import { useEffect } from "react"
import axios from "axios";
import Cookies from "js-cookie"
import { toast } from "react-toastify";

const LogoutPage = () => {

  useEffect(() => {
    axios.get("/api/auth/logout").then(async res => {
      await Cookies.remove("access_token")
      await localStorage.removeItem("refresh_token")
      toast.success("Vous êtes déconnecté")
      setTimeout(() => {
        window.location.href = "/"
      }, 1000)
    }).catch(err => {
      toast.error("Vous étiez déjà déconnecté")
      setTimeout(() => {
        window.location.href = "/"
      }, 1000)
    })
  }, [])

  return (
    <div>
        Déconnexion en cours...
    </div>
  )
};

export default LogoutPage;