import React from "react"

export default function IntInput(
	{fID, label, value, disabled, handleChange, error}) {

	// Note : errors will be implemented later with parent component ...
	
	value = String(value)
	value = value.replace(/[^0-9]/g, "")
	value = parseInt(value)
	if (isNaN(value)) { value = String(value) }

	return (
<>
<div className={"input-integer form-group has-content" 
				+ (disabled ? " is-disabled" : "") 
				+ (error ? " has-error" : "")} key={fID}>

	{label &&
		<label htmlFor={"input-" + fID} className="label">{label}</label>
	}

	<input
		type="number"
		value={value}
		onChange={(e) => handleChange(e.target.value)}
		id={"input-" + fID}
		name={fID}
		disabled={disabled ?? false}
	/>

	<div className="baseline"></div>

	<div className="errors">

		{error && 
		<div className="error-item"> {error} </div>
		}

	</div>

</div>
</>
	)
}