import React, { useRef } from "react";

import AuthService from "services/AuthService"


function StatusInfos ({module}) {
    if (module === "my-data") {
        return (
<>
Si le status indique "OK", c'est que tout va bien. Sinon, il peut être composé des codes suivants :
<ul style={{marginTop: "5px"}}>
    <li> INC : il manque certaines données ou fichiers (fiche incomplète) </li>
    <li> MAJ : mise à jour nécessaire sur certaines données ou fichiers </li>
    <li> VAL : des fichiers doivent être validés par une personne responsable </li>
</ul>
</>
        )
    } else if (module === "listing") {
        return (
<>
Les status non "OK" indiquent les membres avec une fiche ayant des problèmes 
(et ne pouvant donc pas s'inscrire pour le camp).<br/>
Les problèmes sont indiqués avec les codes suivant :
<ul style={{marginTop: "5px"}}>
    <li> INC : il manque certaines données ou fichiers (fiche incomplète, dépend du parent ou chef) </li>
    <li> MAJ : mise à jour nécessaire (dépend du parent ou chef) </li>
    <li> VAL : il y a des <strong>fichiers à valider</strong> dans la fiche membre (par un/une secrétaire, ou un directeur de camp)</li>
</ul>
</>
        )
    } else {
        return null
    }
}


export function MyDataInfos () {
    const profile = useRef(AuthService.getProfileDescription())
    if (profile.current.startsWith("parent")) {
        return (
<section className="member-basic">
    <div className="info-section">
        Dans le tableau ci-dessous, vous devriez voir une ligne correspondant à chaque enfant à votre charge 
        inscrit à la CSSB. En cliquant sur la ligne correspondante, vous pourrez accéder à sa <strong>fiche membre</strong> afin de :
        <ul>
            <li> consulter l'ensemble des données gardées par la CSSB </li>
            <li> mettre à jour les certaines données, et rajouter éventuellement celles manquantes </li>
        </ul>
        <StatusInfos module="my-data"/>
    </div>
</section>
        )
    } else if (profile.current.startsWith("chef")) {
        return (
<section className="member-basic">
    <div className="info-section">
        Accède à ta <strong>fiche membre</strong> en cliquant sur la ligne correspondante du tableau, afin 
        de la mettre à jour. En particulier :
        <ul style={{marginTop: "5px"}}>
            <li> vérifie ton adresse email principale et ton numéro de téléphone perso</li>
            <li> ajoute de nouveaux diplômes si besoin </li>
        </ul>
        <StatusInfos module="my-data"/>
    </div>
</section>
        )
    } else {
        return (
<section className="member-basic">
    <div className="info-section">
        Accès à vos données personnelles, en tant que {profile.current}.
        <br></br><br></br>
        <StatusInfos module="my-data"/>
    </div>
</section>
        )
    }

    
}

export function ListingInfos () {
    const profile = AuthService.getProfileDescription()
    return (
<section className="member-basic">
    <div className="info-section">
        Liste des membres de la CSSB associé à ce compte ({profile}) :
        <ul style={{marginTop: "5px"}}>
            <li> cliquez sur l'une des lignes du tableau pour accéder à la fiche </li>
        </ul>
        <StatusInfos module="listing"/>
    </div>
</section>
    )
}

export function MemberInfo ({
    from,
    problems,
    labels,
}) {
    let probList = [];
    for (const [fID, prb] of Object.entries(problems)) {
        if (prb && labels[fID]) {
            probList.push({"label": labels[fID], "problem": prb})
        }
    }
    if (from === "medic") {
        return null
    }
    // TODO : modify first part of the text, depending on from and profiles ...
    return (
<section className="member-basic">
    <div className="info-section">
        Quelques conseils :
        <ul style={{margin: "10px 0px"}}>
            <li> n'hésitez pas à regarder les informations sur chaque champ (donnée) en cliquant sur le bouton [?]) </li>
            <li> les champs que vous pouvez modifier ont un petit crayon bleu à côté, cliquer dessus pour les éditer </li>
            <li> n'oubliez pas d'enregistrer une fois la modification effectuée </li>
        </ul>
        {probList.length > 0 &&
        <>
        Il y a actuellement {probList.length} problème{probList.length > 1 ? "s" : ""} à 
        régler (voir les champs en rouge dans la fiche) :
            <ul style={{color: "red", margin: "10px 0px"}}>
            {probList.map(({label, problem}, idx) => (
                <li key={idx}> {label} : {problem} </li>
            ))}</ul>
        </>
        }
    </div>
</section>
    )
}