import React, { useRef } from "react"
import { Link, useHistory, useLocation } from 'react-router-dom'
import DropdownMenu from "../../components/DropdownMenu"
import IconButton from '../../components/IconButton'
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/down-arrow.svg'

import AuthService from "services/AuthService"

function DashHeader ({ paths, withSwitch=false }) {

	const currentMatricule = useLocation().pathname.split("/")[3]
	const user = useRef(AuthService.getCurrentUser())

	const history = useHistory();
	function changeMember(matricule){
		history.push(`/dashboard/my-data/${matricule}`)
	}
	
	return (
		<div className="admin-header">

			<div className="flex flex-baseline">
				
				<div className="paths">
				<Link key="dash" to="/dashboard" className="path">Tableau de bord</Link>
				{paths && paths.map((path, idx) => {
					return (
						<Link key={idx} to={path.url} className="path">&nbsp;/ {path.name}</Link>
					)
				})}</div>
			</div>

			{withSwitch && user.current.matricules.length > 1 &&
			<DropdownMenu
                header={() => <IconButton icon={<ArrowDownIcon />} side="right" label={currentMatricule} onClick={() => {}} />}
                content={() => {
						
                        return (
                            user.current.matricules.map((matricule) => {
                                return <div key={matricule} onClick={() => {
                                    changeMember(matricule)
                                }}>{matricule}</div>
                            })
                        )
                    }}
                />}

			<div className="flex flex-baseline">
				<div className="email">
					{user.current.email} {user.current.profiles.length !== 0 ? `[${user.current.profiles}]` : ""}
				</div>
				
			</div>

		</div>	
	)
}

export default DashHeader