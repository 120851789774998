import React from "react"
import DashHeader from 'components/Dash/Header'
import DashSidebar from 'components/Dash/Sidebar'

export default function DashHome() {

	return (
		<div className="admin-page">

			<DashSidebar current="home" />

			<DashHeader paths={[
				{
					name: "Accueil",
					url: "/dashboard"
				}
			]} />

			<div className="admin-body">

				<div className="admin-section">
					<div className="admin-section-title">Bienvenue sur votre espace personnel !</div>
                    <br/>
                    <em>Celui-ci à pour but de centraliser des fonctionnalités primordiales à la vie de la CSSB,
                    que vous soyez parent, chef, secrétaire, directeur de camp ou autre ...</em>

                    <br/><br/>
                    <strong>Pour les nouveau arrivant :</strong> avant toute chose, veuillez compléter vos données personnelles 
                    (ou celles de vos enfants) dans l'onglet <strong>Mes Données</strong> à gauche. Avoir une fiche complétée et validée est
                    obligatoire avant de pouvoir s'inscrire au moindre évènement (camp d'été, inscription à l'année, ...). 

                    <br/><br/>
                    Pour plus d'informations : vous êtes actuellement connecté avec un <strong>compte utilisateur</strong> (identifié par
                    l'adresse email en haut à droite), avec lequel vous pouvez accéder aux fiches des <strong>membres associés</strong> à votre 
                    compte. <em>Cela peut être un ou plusieurs enfants (si vous êtes parent), vous-même (si vous être un chef ou un membre administratif),
                    ou vous-même et vos enfants (si êtes membre administratif d'une compagnie avec des enfants inscrits à la CSSB).</em>

                    <br/><br/>
                    À gauche, vous pouvez voir différents onglets :
                    <ul>
                        <li>
                            <strong>Mes Données :</strong>&nbsp;
                            accéder aux données des membres associées à ce compte utilisateur 
                        </li>
                        <li>
                            <strong>S'inscrire :</strong>&nbsp;
                            pour pouvoir vous inscrire aux différents évènements proposés par la CSSB (camp d'été, inscription à l'année, ...)
                        </li>
                        <li>
                            <strong>Support :</strong>&nbsp;
                            un formulaire de contact en cas de problème, requête, suggestion, ...
                        </li>
                    </ul>
                    <br/>
                    Si vous êtes <strong>chef</strong> ou <strong>membre administratif</strong> (voir profile indiqué en haut à droite), vous avez à gauche :
                    <ul>
                        <li>
                            <strong>Listing :</strong>&nbsp;
                            la liste des membres auxquels vous avez accès, pour regarder leur fiches personnelles,
                            éventuellement valider leur documents, etc ... 
                        </li>
                        <li>
                            <strong>Évènements :</strong>&nbsp;
                            liste de l'ensemble des évènements CSSB, et aperçu parmi la liste des membres auxquels vous avez accès
                            des inscriptions, réceptions de paiement, etc ...
                        </li>
                    </ul>
                    <br/>
                    <em>
                        Le site est un peu rustique, car toujours en cours de développement ... 
                        n'hésitez pas à utiliser le formulaire de contact si vous voyez le moindre problème, 
                        ou piste d'amélioration ;)
                    </em>

				</div>

			</div>

			

		</div>
	)
}