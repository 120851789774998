import React, { useEffect, useState } from "react"

import { RiCloseLine } from "react-icons/ri"

export default function TextInput({
    name,
    label,
    value,
    disabled,
    onChange,
    errors,
    className,
    type,
    category,
    placeholder,
    baselineNoMargin,
}) {

	const [isFocused, setIsFocused] = useState(false)
	const [hasContent, setHasContent] = useState((value && value.length > 0))

	function handleChange(event) {

		if (name === 'email' && event.target.value.trim)
			event.target.value = event.target.value.trim().toLowerCase()

		let value = event.target.value

		if(!isNaN(value) || value.length > 0) {
			if(!hasContent)
				setHasContent(true)
		} else {
			if(hasContent)
				setHasContent(false)
		}

		onChange(event)
	}

	useEffect(() => {
		if(value && (value.length > 0 || !isNaN(value)))
			setHasContent(true)
		else
			setHasContent(false)
	}, [value])

	return (
<div className={"form-group" + 
                (disabled ? " is-disabled" : "") + 
                (errors ? " has-error" : "") + 
                (hasContent ? " has-content" : "") + 
                (isFocused ? " is-focused" : "") + 
                (className ? " " + className : "")}>

    {label &&
        <label htmlFor={"input-" + name} className="label">{label}</label>
    }

    <input
        type={type ? type : "text"}
        value={value ? value : ""}
        onChange={handleChange}
        id={"input-" + name}
        name={name}
        category={category}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        disabled={disabled ? disabled : false}
        placeholder={placeholder ? placeholder : ""}
    />

    <div className="input-icon">
        {errors && <RiCloseLine />}
    </div>

    <div className={"baseline "+(baselineNoMargin ? "baseline-no-margin" : "")}></div>

    <div className="errors">

        {errors && errors.map((error, idx) => {
            return (
                <div key={idx} className="error-item">
                    {error}
                </div>
            )
        })}

    </div>

</div>
	)
}