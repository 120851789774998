const validString = (str, options = {}) => {

	if(str === undefined || str.length === 0)
		return "Ce champ est obligatoire"

	if (options.minLength) {
		if (str.length < options.minLength)
			return "La valeur de ce champ doit contenir plus de " + options.minLength + " caractères"
	}

	if (options.maxLength) {
		if (str.length > options.maxLength)
			return "La valeur de ce champ doit contenir moins de " + options.maxLength + " caractères"
	}

	return null
}

const postalCode = postalCode => {
	if (postalCode === undefined || postalCode.length === 0)
		return "Ce champ est obligatoire"

	if (postalCode.length !== 5)
		return "Ce champ ne peut contenir que 5 caractères"

	if (postalCode.replace(/\D/g, "").length !== 5)
		return "Ce champ ne peut contenir que des chiffres"

	return null
}

const phone = phone => {
	if (phone === undefined || phone.length === 0)
		return "Ce champ est obligatoire"

	return null
}

const email = (email, options = {}) => {

	let haveEmail = true

	if (email === undefined || email.length === 0) {

		haveEmail = false

		if(options.required !== false)
			return "Ce champ est obligatoire"
	}

	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	if (haveEmail && !re.test(email))
		return "Ce n'est pas un email valide"

	return null
}

const number = (number, options = {}) => {

	if (number === undefined)
		return "Ce champ est obligatoire"

	if(isNaN(number))
		return `Cette valeur n'est pas un nombre : ${number}`

	if (options.min) {
		if (number < options.min)
			return `Nombre inférieur à ${options.min}`
	}

	if (options.max) {
		if (number > options.max)
			return `Nombre supérieur à ${options.max}`
	}

	return null
}

const file = (file, options = {}) => {
	if (file === undefined){
		return "Merci de fournir un fichier"
	}
	if (file.constructor === Object && Object.entries(file).length === 0) {
		return "Merci de fournir un fichier"
	}
	if (!(file instanceof File)){
		return "Cette valeur n'est pas valide"
	}
	return null
}

const date = (date, options = {}) => {
	if (date === undefined) {
		return "Ce champ est obligatoire"
	}
	if(isNaN(Date.parse(date))) {
		return "Cette date n'est pas valide"
	}
	return null
}

const notUndefined = data => {
	return (data === undefined) ? "Ce champ est obligatoire" : null
}

const moduleExport = {
	validString,
	postalCode,
	phone,
	email,
	number,
	file,
	date,
	notUndefined
}

export default moduleExport