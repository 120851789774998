import React, { useState, useEffect } from "react";
import axios from "axios";

import DashHeader from 'components/Dash/Header'
import DashSidebar from 'components/Dash/Sidebar'

import Table from "components/Table";

import FullPageLoading from "components/FullPageLoading"
import { Collapsible } from "components/utils/dev"

export default function PresenceList({
    history
}) {

    const [isLoading, setIsLoading] = useState(false)
    const [headers, setHeaders] = useState()
    const [events, setEvents] = useState()

    useEffect(() => {
        setIsLoading(true)
        axios.get("/api/events/presence" 
        ).then(res => {
            setHeaders(res.data.headers);
            setEvents(res.data.events);
        }).catch(err => {
            console.log("Error ", err)
        }).finally(() => setIsLoading(false))
    }, []);

	return (
		<div className="admin-page">

            <FullPageLoading show={isLoading}/>

			<DashSidebar current="presence" />
			<DashHeader paths={[{ name: "Présence", url: "/dashboard/presence" }]} />

			<div className="admin-body">

                <div className="admin-section">
                    Liste des évènements pour lesquels des fiches de présence sont requises (cliquer pour accéder aux données) :
                </div>

                {headers && events &&
                <Table
                    uniqueID="my-events"
                    headers={headers}
                    data={events}
                    onItemClicked={event => history.push(`/dashboard/presence/${event.eID}`)}
                />}

                {/* For development and debug ... */}
                <Collapsible header="headers" data={headers} />
                <Collapsible header="events" data={events} />

			</div>

			

		</div>
	)
}