import React, { useEffect, useRef, useState } from "react"

import { RiCloseLine } from "react-icons/ri"

const Phone = props => {

	const inputRef = useRef()
	const longestInputValue = useRef(props.value)

	const [isFocused, setIsFocused] = useState(false)
	const [hasContent, setHasContent] = useState(props.value.trim().length > 0)

	const formatContent = content => {

		let newValue = ""

		for (var i = 0; i < content.length; i++) {
			let char = content.charAt(i)

			if(Number.isInteger(parseInt(char))) {

				if (newValue[0] === "0") {
					if (i !== 0 && i % 2 === 0)
						newValue += " "
				}
				else if (newValue[0] === "+") {
					if (i === 3 || i === 4 || i === 6 || i === 8 || i === 10)
						newValue += " "
				}
				else {
					if (i === 1 || i === 3 || i === 5 || i === 7)
						newValue += " "
				}

				newValue += char
			}
			else if (char === "+")
				newValue += char

		}

		return newValue
	}

	const handleChange = e => {

		let { value } = e.target

		if(value.length > 0)
			setHasContent(true)
		else
			setHasContent(false)

		let rawValue = value.trim().replaceAll(" ", "")

		if(rawValue.length <= 15) {

			let newValue = formatContent(rawValue)

			if(value.length < longestInputValue.current.length)
				newValue = newValue.trim()

			e.target.value = newValue

			props.onChange(e)
		}

		if(value.length > longestInputValue.current.length)
			longestInputValue.current = value

	}

	const handleFocus = () => {
		setIsFocused(true)
	}

	const handleBlur = e => {
		let rawValue = e.target.value.trim().replaceAll(" ", "")
		e.target.value = formatContent(rawValue)
		e.target.category = props.category

		props.onChange(e)

		setIsFocused(false)
	}

	const handleLabelClick = e => {
		e.preventDefault()
		inputRef.current.focus()
	}

	useEffect(() => {
		if(props.value.length > 0)
			setHasContent(true)
		else
			setHasContent(false)
	}, [props.value])

	return (
		<div className={"form-group group-phone" + (props.disabled ? " is-disabled" : "") + (props.errors ? " has-error" : "") + (hasContent ? " has-content" : "") + (isFocused ? " is-focused" : "") + (props.className ? " " + props.className : "")}>

			{props.label &&
				<label htmlFor={"input-" + props.name} onClick={handleLabelClick} className="label">{props.label}</label>
			}

			<input
				ref={inputRef}
				type="text"
				value={props.value}
				onChange={handleChange}
				id={"input-" + props.name}
				name={props.name}
				category={props.category}
				onFocus={handleFocus}
				onBlur={handleBlur}
				disabled={props.disabled ? props.disabled : false}
			/>

			<div className="input-icon">
				{props.errors &&
					<RiCloseLine />
				}
			</div>

			<div className="baseline"></div>

			<div className="errors">

				{props.errors && props.errors.map((error, idx) => {
					return (
						<div key={idx} className="error-item">
							{error}
						</div>
					)
				})}

			</div>

		</div>
	)
}

export default Phone