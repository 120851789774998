import React, {useState} from "react"
import Modal from "components/Modal"


export default function ConfirmPopup({title, onConfirm, onReject, withComment=false}) {

	const [comment, setComment] = useState("")
	const error = (comment === "") && withComment

	return (
		<Modal fixed={true} className="confirm-modal" show={true} onClose={onReject}>

			<div className="form-title title mb-30">{title}</div>

			{withComment && 
				<div className={"form-group has-content"} style={{marginBottom: "30px", width: "500px"}}>

					<label htmlFor="input-comment" className="label">Veuillez rentrer les détails ci-dessous :</label>
					
					<input
						type="text"
						value={comment}
						onChange={(e) => setComment(e.target.value)}
					/>

					<div className="baseline"></div>

				</div>
			}
			
			<div className="flex center gap-20 flex-horizontal">
				<div className="pointer blue-link" onClick={e => onReject(e)}>Annuler</div>
				{error ? (
					<div className="btn is-disabled">Confirmer</div>
				) : (
					<div className="btn" onClick={() => onConfirm(comment)}>Confirmer</div>
				)}
			</div>

		</Modal>
	)
}