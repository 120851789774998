import React, { useCallback, useEffect, useState } from "react"
import axios from "axios"
import { useParams } from 'react-router'
import { toast } from "react-toastify"


import FullPageLoading from 'components/FullPageLoading'

import DashHeader from "components/Dash/Header"
import DashSidebar from "components/Dash/Sidebar"
import PaymentChoice from "pages/Events/comp/PaymentChoices"
import PaymentInfo from "pages/Events/comp/PaymentInfos"

import { Collapsible } from "components/utils/dev"

export default function Register({
    path,   // Note : path implicitly transmitted through the <DashRoute> component
}) {
    const { eID } = useParams()
    const from = path.split("/")[2]

    const [isLoading, setIsLoading] = useState(false)
    const [event, setEvent] = useState({})
    const [members, setMembers] = useState([])

    const reloadEvent = useCallback(() => {
        setIsLoading(true)
        axios.get(`/api/events/my-events/${eID}`).then(res => {
            setEvent(res.data.event)
            setMembers(res.data.members)
        }).catch(err => {
            if (err.response)
                toast.error(err.response.data.error)
            else {
                toast.error("Une erreur est survenue")
                console.log(err)
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }, [eID])

    useEffect(() => {
        reloadEvent();
    }, [reloadEvent])

    return (
<>

<FullPageLoading show={isLoading} />

<div className="admin-page">

    <DashSidebar current={from} />

    <DashHeader paths={[
        {
            name: "Mes Inscriptions",
            url: `/dashboard/${from}`
        },
        {
            name: event?.title,
            url: `/dashboard/${from}/${eID}`
        }

    ]} />

    <div className="admin-body">

        {event.rates && event.rates.length > 0 &&
        <div className="admin-section">
            Prix de l'évènement
            <ul>
                {event.rates.map((rate, idx) => 
                    <li key={idx}> {rate.label} : {rate.amount}€ <br/><em>{rate.descr}</em></li>
                )}
            </ul>
        </div>}

        {event.options && event.options?.length !== 0 &&
        <div className="admin-section">
            Option{event.options.length > 1 ? "s": ""} proposée{event.options.length > 1 ? "s": ""} :
            <ul>
                {event.options.map((option, idx) => 
                    <li key={idx}> {option.label} : {event.rates.length > 0 ? "+": ""}{option.amount}€ </li>
                )}
            </ul>
        </div>}

        {/* {event.discounts && event.discounts?.length !== 0 &&
        <div className="admin-section">
            Reduction(s) possible(s) (par personne inscrite):
            <ul>
                {event.discounts.map((discount, idx) => 
                    <li key={idx}> {discount.label} : -{discount.amount}€ </li>
                )}
            </ul>
        </div>} */}

        {members.length !== 0 &&
        <div className="admin-section">
            Évènement accessible pour :
            <ul>
                {members.map((member, idx) => 
                    <li key={idx}> {member.firstName} {member.lastName} ({member.matricule}, fiche membre : {member.status}) 
                    -- <strong> {event.status} </strong>
                    </li>
                )}
            </ul>
        </div>}

        {!event?.authorized && members.length !== 0 &&
        <div className="admin-section">
            <strong>Inscription non autorisée</strong> : chaque fiche membre doit être complète, mise-à-jour et validée
            <ul>
                <li> si la fiche membre indique INC ou MAJ, veuillez compléter ou mettre à jour les champs ou fichiers
                    indiqués (voir "Mes Données")
                </li>
                <li> si la fiche membre indique uniquement VAL, une validation est encore nécessaire de la part de votre 
                    secrétaire (ou d'un directeur de camp) 
                </li>
            </ul>
        </div>}

        {event?.authorized && !event?.registered && 
            event.paymentModes && event.paymentModes.length !== 0 &&
        <div className="admin-section">
            Pour lancer l'inscription, veuillez tout choisir un <strong>mode de paiement</strong> ci-dessous ...<br/>
            <strong>Attention</strong> : une fois le mode choisi, vous ne pourrez plus le changer (à moins de faire une requête via le formulaire de support)
            {Object.entries(event.paymentModes).map(([mode, data]) => 
                <PaymentChoice key={mode} 
                    mode={mode} 
                    data={{...data, matricules:members.map(m => m.matricule)}}
                    event={event}
                    onRegistration={() => reloadEvent()}
                />
            )}
        </div>}

        {event.registered && !event?.paid &&
        <div className="admin-section">
            <PaymentInfo 
                data={{...event.registered, amount:event.amount, discounts:event.userDiscounts, eID:event.eID}}/> 
        </div>}

        {event.registered && event.paid &&
        <div className="paymentMode">
            Paiement reçu le {event.paid}
            {!event.validated &&
                <div style={{textAlign: "center"}}>
                    <strong >
                    Le paiement reçu est inférieur au montant attendu ({event.amount}€)
                    <br/>
                    Veuillez contacter rapidement le Service Informatique CSSB : service.informatique@cssb.fr ...
                </strong>
                </div>
            }
        </div>}

        {/* For development and debug ... */}
        <Collapsible header="event" data={event} />
        <Collapsible header="members" data={members} />
    </div>

</div>
</>
    )
}