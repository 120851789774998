import { useState, useEffect } from "react"

import { toast } from 'react-toastify';
import Modal from "../Modal"

import { TextInput, DateInput, SelectInput, ListInput } from "../Inputs/Inputs"
import axios from 'axios';


const CreateAccountPopup = props => {

    const [accountData, setAccountData] = useState(props.prefill ?? {"telephones": []});
    const [errors, setErrors] = useState({});
    const [cies, setCies] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            const response = await axios.get("/api/companies/list");
            var cies = response.data.map(
                (cie) => {
                    return {"name": cie["descr"], "value": cie["idx"]}
                })
            setCies(cies);
        };
        fetch();
    }, []);
    
    
    const validate = {
        "email": (value) => {
            if (!value ?? value.length <= 7 ?? value.length >= 254) return "Email valide requis";
            if (!String(value)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) return "L'email n'est pas valide";
            return null;
        },
        "firstName": (value) => {
            if (!value ?? value.length <= 1) return "Prénom trop court";
            if (value.length >= 30) return "Prénom trop long";
            return null;
        },
        "lastName": (value) => {
            if (!value ?? value.length <= 1) return "Nom trop court";
            if (value.length >= 30) return "Nom trop long";
            return null;
        },
        "birthDate": (value) => {
            if (!value) return "Date de naissance requise";
            const year = new Date(value).getFullYear();
            if (year < new Date().getFullYear() - 100 ?? year > new Date().getFullYear() - 11) return "Date de naissance invalide";
            return null;
        },
    }


	const handleReject = e => {
        toast.error("Création de compte annulée");
		if (props.onReject)
            props.onReject(e)
	}

    const handleInputChange = e => {
        const { name, value } = e.target;
        setErrors({ ...errors, [name]: null });
        setAccountData({ ...accountData, [name]: value });
        console.log(JSON.stringify(accountData));
    };

    const handleSubmit = e => {
        e.preventDefault();

        // Validate form before submitting
        const _errors = {};
        for (const key in validate) {
            const error = validate[key](accountData[key]);
            if (error) _errors[key] = [error];
        }

        console.log(_errors);

        if (Object.keys(_errors).length > 0) {
            setErrors(_errors);
            console.log(errors);
            return;
        }

        // Submit form
        console.log(accountData);
        axios.post("/api/accounts/new", {
            "data": accountData
        })
        .then(res => {
            toast.success("Compte créé avec succès");
            if (props.onConfirm){
                props.onConfirm(e)
            }
                
        })
        .catch(err => {
            toast.error("Erreur lors de la création du compte");
            console.log(err);
        })
    }

	return (
		<Modal fixed={true} className="confirm-modal" show={true} onClose={props.onReject}>

			<div className="form-title title mb-30">
				Créer un compte
			</div>

            <form>

                <div className="mb-30 flex flex-horizontal flex-space-around">
                        <TextInput
                            className="mr-15"
                            label="Email*"
                            name="email" value={accountData["email"] ?? ""}
                            onChange={handleInputChange}
                            errors={errors["email"]} />

                        <SelectInput
                            label="Type de Membre*"
                            name="memberType"
                            options={[
                                // TODO : retrieve that from an API call
                                {
                                    "name": "Administratif",
                                    "value": "base"
                                },
                                {
                                    "name": "Compagnon",
                                    "value": "companion"
                                },
                                {
                                    "name": "Chef",
                                    "value": "chief"
                                }
                            ]}
                            value={accountData["memberType"] ?? "base"}
                            onChange={handleInputChange}
                            errors={errors["memberType"]}
                        />
                </div>

                <div className="mb-30 flex flex-horizontal flex-space-around">
                    <TextInput
                        errors={errors["firstName"]}
                        className="mr-15"
                        label="Prénom*"
                        name={"firstName"}
                        value={accountData["firstName"] ?? ""}
                        onChange={handleInputChange}
                    />

                    <TextInput
                        errors={errors["lastName"]}
                        label="Nom*" 
                        name={"lastName"}
                        value={accountData["lastName"] ?? ""}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="mb-30 flex flex-horizontal flex-space-around">
                    <DateInput
                        errors={errors["birthDate"]}
                        label="Date de naissance*"
                        name={"birthDate"}
                        value={accountData["birthDate"] ?? null}
                        onChange={handleInputChange}
                    />

                    <SelectInput
                        label="Compagnie*"
                        name="company"
                        options={cies}
                        value={accountData["company"] ?? undefined}
                        onChange={handleInputChange}
                        errors={errors["company"]}
                    />
                </div>

                <div className="mb-30">
                    <ListInput
                        label="Téléphone"
                        name="telephones"
                        value={accountData["telephones"] ?? []}
                        onChange={handleInputChange}
                        errors={errors["telephones"]}
                    />
                </div>

            </form>


			<div className="flex center gap-20 flex-horizontal">
				<div className="pointer blue-link" onClick={handleReject}>Annuler</div>
				<div className="btn" onClick={handleSubmit}>Confirmer</div>
			</div>

		</Modal>
	)
}

export default CreateAccountPopup;