import React, { useEffect, useRef, useState } from "react"

import { FaCheck } from "react-icons/fa"

const CheckboxInput = props => {

	const checkboxElem = useRef(null)

	const [isChecked, setIsChecked] = useState(false)

	const toggleChecked = () => {
		checkboxElem.current.click()
	}

	const onCheckboxChange = e => {
		setIsChecked(e.target.checked)

		let newEvent = { ...e }

		newEvent.target.value = e.target.checked

		props.onChange(newEvent)
	}

	useEffect(() => {

		if(typeof props.checked === "boolean")
			setIsChecked(props.checked)

	}, [props.checked])

	return (
		<div onClick={toggleChecked}
			className={"form-group group-checkbox" + (props.disabled ? " is-disabled" : "")
							+ (isChecked ? " is-checked" : "") + (props.className ? " " + props.className : "")}>

			<input
				disabled={props.disabled ? true : false}
				ref={checkboxElem}
				type="checkbox"
				checked={isChecked}
				style={{
					display: "none"
				}}
				onChange={onCheckboxChange}
				name={props.name}
				category={props.category}
			/>

			<div className="input-check">
				{isChecked &&
					<FaCheck size={11} />
				}
			</div>

			{props.label &&
				<label htmlFor={"input-" + props.name} className="label">{props.label}</label>
			}

		</div>
	)
}
export default CheckboxInput