import React, { useEffect, useState, useCallback } from "react"
import axios from 'axios'

import OptionsDisplay from "components/Admin/OptionsDisplay"
import Cyssbot from 'assets/imgs/cyssbot.png'
import MedicHeader from 'components/Admin/MedicHeader'

export default function MemberHeader({
    from,
    member,
    cieDisplay,
    memberTypeDisplay,
    onUpdate,
}){
    const matricule = member.matricule

    const [imgSrc, setImgSrc] = useState("")
    const getPhoto = useCallback(() => {
        if (matricule){
            axios.get(`/api/files/${matricule}/photo`, { 
                responseType: "arraybuffer" 
            }).then(res => {
                const base64 = btoa(
					new Uint8Array(res.data).reduce(
						(data, byte) => data + String.fromCharCode(byte),
						'',
					),
			  	);
			 	setImgSrc("data:;base64," + base64);
            }).catch(() => {
                setImgSrc(Cyssbot)
            })
        }
    }, [matricule])
    useEffect(() => {getPhoto()}, [getPhoto])

    return (
        <div className="member-header">
            <div>

                <div className="member-pic"> 
                    <img src={imgSrc} alt="None"/> 
                </div>

                <div className="member-name">
                    {member.firstName} {member.lastName} [{member.matricule}]<br/>
                    {cieDisplay && 
                        <OptionsDisplay 
                            member={member} 
                            fID={"company"}
                            onUpdate={onUpdate} 
                            {...cieDisplay}/>
                    }
                </div>

                <div className="member-name">
                    né(e) le {member.birthDate}<br/>
                    {memberTypeDisplay && 
                        <OptionsDisplay 
                            member={member} 
                            fID={"memberType"}
                            onUpdate={onUpdate} 
                            {...memberTypeDisplay}/>
                    }
                </div>

                {/* <StatusDisplay member={member} changeCallback={reloadUser}/> */}

            </div>

            {from === "medic" && <MedicHeader/>}

        </div>
    )
}