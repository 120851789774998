import React from "react"

export default function TextInput({
    fID, 
    label, 
    value, 
    disabled, 
    handleChange, 
    error
}){

	return (
<div className={"form-group has-content" 
                + (disabled ? " is-disabled" : "") 
                + (error ? " has-error" : "")} key={fID}>

    <label htmlFor={"input-" + fID} className="label">{label}</label>

    <input
        type={"text"}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        id={"input-" + fID}
        name={fID}
        disabled={disabled ?? true}
    />

    <div className="baseline"></div>

    {error &&
    <div className="errors">
        <div className="error-item"> {error} </div>
    </div>
    }

</div>
	)
}