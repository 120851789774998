import React, { useEffect, useRef, useState } from "react"
import { toast } from 'react-toastify'
import { RiCloseLine } from "react-icons/ri"

export default function DateInput(props){

	const inputRef = useRef()

	const [isFocused, setIsFocused] = useState(false)
	const [hasContent, setHasContent] = useState((props.value && props.value.length > 0))

	const handleChange = e => {

		let date = e.target.value

		setHasContent(date ? true : false)

		if (props.onChange) {
			props.onChange({
				"target": {
					"value": date,
					"name": props.name
				}
			})
		}

		return
	}

	const handleFocus = () => setIsFocused(true)

	const handleBlur = () => setIsFocused(false)

	useEffect(() => {

		if(inputRef.current) {
			if (window.lastDateAlert) {
				return
			}
			window.lastDateAlert = Date.now()
			if (inputRef.current.type !== "date")
				toast.error("Votre navigateur ne supporte pas les champs \"date\", "
					+ "veuillez rentrer les dates sous forme : "
					+ "YYYY-MM-DD (ex: 2008-07-03 pour 3 juillet 2008)",
					{ autoClose: 15000 })
		}

		return function () {
			if (window.lastDateAlert) {
				window.lastDateAlert = undefined
			}
		}

	}, [inputRef])

	return (
		<div className={"form-group group-date"
			+ (props.errors ? " has-error" : "")
			+ (hasContent ? " has-content" : "")
			+ (isFocused ? " is-focused" : "")
			+ (props.className ? " " + props.className : "")}>

			{props.label &&
				<label htmlFor={"input-" + props.name} className="label">{props.label}</label>
			}

			<input
				ref={inputRef}
				type={props.type ? props.type : "date"}
				value={props.value ? props.value : ""}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				id={"input-" + props.name}
				name={props.name}
				disabled={props.disabled ? props.disabled : false}
				placeholder={"YYYY-MM-DD"}
			/>

			<div className="input-icon">
				{props.errors &&
					<RiCloseLine />
				}
			</div>

			<div className="baseline"></div>

			<div className="errors">

				{props.errors && props.errors.map((error, idx) => {
					return (
						<div key={idx} className="error-item">
							{error}
						</div>
					)
				})}

			</div>

		</div>
	)
}