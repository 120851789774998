import React from "react"

import { CheckIcon } from "icons";

export default function IconButton ({
    className,
    onClick,
    icon,
    side,
    label,
    active,
}) {
	return (
		<div className={"icon-btn " + (className ? className : "")} onClick={onClick}>
			{(!side || side === "left") &&
				<div className="icon">{icon}</div>
			}

			<div>{label}</div>
			{side === "right" &&
				<div className="icon">{icon}</div>
			}
            {active &&
				<div className="icon"><CheckIcon/></div>
			}
		</div>
	)
}