import { React, useEffect, useState } from 'react'

import { AiOutlineArrowUp } from 'react-icons/ai'


function ReturnToTop() {

    const [show, setShow] = useState(false)

    const onScroll = (e) => {
        if(window.scrollY > 300 && !show) {
            setShow(true)
        } else {
            setShow(false)
        }
    }

    useEffect(() => {
        document.addEventListener("scroll", onScroll)
        return function () {
            document.removeEventListener("scroll", onScroll)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )

    const scrollToTop = () => {
        window.scroll({ top: 0, behavior: "smooth" })
    }

    return (
        <div onClick={scrollToTop} className={"return-to-top" + (show ? " is-visible" : "")}>
            <AiOutlineArrowUp color="#fff" size={30} />
        </div>
    )
}

export default ReturnToTop