export function realDiscount(discounts, amount, paymentMode) {
    const appliedDiscounts = discounts.filter(d => d.notApplyTo ? !d.notApplyTo.includes(paymentMode) : true)
    const nonAppliedDiscounts = discounts.filter(d => d.notApplyTo ? d.notApplyTo.includes(paymentMode) : false)
    let userDiscount = ""
    if (appliedDiscounts.length !== 0) {
        userDiscount = `(${appliedDiscounts.map(d => d.label).join(", ")})`
    }
    let userAmount = amount
    if (nonAppliedDiscounts.length !== 0) {
        userAmount += nonAppliedDiscounts.map(d => d.amount).reduce((acc,val) => acc + val, 0)
    }
    return [userDiscount, userAmount]
}