import React, { useState } from "react";
import { useLocation } from 'react-router-dom';

function Collapsible({ header, data }) {
    const [collapsed, setCollapsed] = useState(true);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    if (params.has("debug")) {
        return (
<div>
    <div className="collapsible-header" onClick={toggleCollapsed}>
        {header} (pour debug ...)<span className="arrow">{collapsed ? '▼' : '▲'}</span>
    </div>
    {collapsed ? null : (
    <div className="collapsible-content">
        <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
    )}
</div>
    )} else {
        return null
    };
}

export {
    Collapsible
}