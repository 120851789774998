import React, { useEffect, useState } from "react"
import axios from 'axios'
import { toast } from "react-toastify"

import Modal from "components/Modal"
import TextInput from 'components/Inputs/OldTextInput'
import FullPageLoading from 'components/FullPageLoading'

export default function MedicAct({
    show,
    eID,
    matricule,
    data,
    onClose,
    reload,
}){
    const [loading, setLoading] = useState(false)

    const [actData, setActData] = useState({...data} ?? {});
    function handleChange(name, value){
        setActData({...actData, [name]: value});
    };
    function close() {
        setActData({})
        if (onClose) onClose()
    }

    function storeAct(){
        setLoading(true)
        axios.post(`api/events/medic/${eID}/act`, 
        {
            "matricule": actData.matricule ?? matricule,
            "reason": actData.reason,
            "acts": actData.acts,
            "obs": actData.obs,
            "provider": actData.provider,   
        }).then(() => {
            toast.success("Acte de soin enregistré")
            setActData(data ?? {})
            close()
            if (reload) reload()
        }).catch(err => {
            if (err?.response?.data.detail) {
				toast.error(JSON.stringify(err.response.data.detail))
			} else {
				toast.error("Erreur bizarre ...")
				console.log("erreur", err)
			}
        }).finally(() => {
            setLoading(false)
        })
    };

    function updateAct(){
        setLoading(true)
        axios.patch(`api/events/medic/${eID}/act`, {
            "matricule": actData.matricule,
            "date": actData.date,
            "reason": actData.reason,
            "acts": actData.acts,
            "obs": actData.obs,
            "provider": actData.provider,   
        }).then(() => {
            toast.success("Acte de soin mis à jour")
            setActData({})
            close()
            if (reload) reload()
        }).catch(err => {
            if (err?.response?.data.detail) {
				toast.error(JSON.stringify(err.response.data.detail))
			} else {
				toast.error("Erreur bizarre ...")
				console.log("erreur", err)
			}
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if (data) setActData(data)
    },[data])

    return (
<Modal 
    fixed={true} 
    className="medicAct-modal" 
    show={show}
>

    <FullPageLoading show={loading}/>

    <div className="form-title title mb-30">
        {!data ? "Nouvel acte de soin" : "Modifier l'acte de soin"}
    </div>

    <form style={{fontWeight: "normal", fontSize: "18px"}}>

        <div className="mb-30 flex flex-horizontal flex-space-around">
            <TextInput
                className="mr-30 medicActInput"
                label="Circonstance / venu pour"
                name={"reason"}
                value={actData["reason"] ?? ""}
                onChange={e => handleChange("reason", e.target.value)}
            />

            <TextInput
                className="medicActInput"
                label="Soin(s) réalisé(s)" 
                name={"acts"}
                value={actData["acts"] ?? ""}
                onChange={e => handleChange("acts", e.target.value)}
            />
        </div>

        <div className="mb-30 flex flex-horizontal flex-space-around">
            <TextInput
                className="mr-30 medicActInput"
                label="Observation"
                name={"obs"}
                value={actData["obs"] ?? ""}
                onChange={e => handleChange("obs", e.target.value)}
            />

            <TextInput
                label="Intervenant (initiales)" 
                name={"provider"}
                value={actData["provider"] ?? ""}
                onChange={e => handleChange("provider", e.target.value)}
            />
        </div>

    </form>

    <div className="flex center gap-20 flex-horizontal">
        <div className="pointer blue-link" onClick={() => close()}>Annuler</div>
        <div className="btn bold" onClick={!data ? storeAct : updateAct}>Enregistrer</div>
    </div>
    
</Modal>
    )}