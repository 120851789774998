import React, { useState, useEffect, useCallback } from "react"
import axios from 'axios'
import { toast } from "react-toastify"

import { 
    DateInput, 
    TextInput, 
    ListInput, 
    IntInput, 
    SelectInput,
    TextareaInput,
} from 'components/Inputs/Inputs'
import { MdEdit, MdQuestionMark, MdSave, MdRestore } from "react-icons/md"

import { HelpPopup } from "components/utils/doc"

function DefaultInput({
    fID, 
    label, 
    value
}) {
    return (
<div key={fID} className={"form-group has-content is-disabled"}>
    {label &&
        <label htmlFor={"input-" + fID} className="label">{label}</label>
    }
    <input
        type="text"
        value={value}
        id={"input-" + fID}
        name={fID}
        disabled={true}
    />
    <div className="baseline"></div>
</div>
    );
}

export default function AutoInput({
    fID, 
    field, 
    setLoading, 
    apiURI,
    statusNonOK=false,
    updateProblems,
}) {

    const { fType, label, value, options, scope, descr, problem } = field

    const [currValue, setCurrValue] = useState(value)
    const [prevValue, setPrevValue] = useState(value)
    
    const [edit, setEdit] = useState(false)
    const [help, setHelp] = useState(false)

    let canEdit = scope > 1
    
    function handleChange(val) {
        setCurrValue(val)
    }

    const save = useCallback(() => {
        // In case no change is done, avoid API call ...
        if (currValue === prevValue) {
            toast.info("Aucun changement effectué");
            setEdit(false)
            return
        }
        setLoading(true)

        axios.put(apiURI, {
            "fields": {[fID]: currValue},
            "statusNonOK": statusNonOK, 
        }).then(res => {
                toast.success(`Mise à jour effectuée (${label})`);
                setPrevValue(res.data.new[fID])
                if (updateProblems) { updateProblems(res.data.problems) }
                if (res.data.email) { toast.success(res.data.email) }
                setEdit(false)
            })
        .catch(err => {
            if (err?.response?.data.detail) {
                toast.error(err.response.data.detail)
            } else {
                toast.error("Erreur bizarre ...")
                console.log(err)
            }
        })
        .finally(() => {
            setLoading(false)
        })
    }, [currValue, prevValue, apiURI, fID, label, setLoading, updateProblems, statusNonOK])

    const restore = useCallback(() => {
        setCurrValue(prevValue)
        setEdit(false)
    }, [prevValue])

    let Input = DefaultInput;

    if (options.length !== 0) {
        Input = SelectInput
    } else {
        switch (fType) {
            case "Integer": Input = IntInput; break;
            case "String": Input = TextInput; break;
            case "Date": Input = DateInput; break;
            case "Array[String]": Input = ListInput; break
            case "Text": Input = TextareaInput; break
            default: canEdit = false
        }
    }

    useEffect(() => {
        if (!edit) return
        function keyAction(event) {
            if (Input !== TextareaInput) {
                if (event.keyCode === 13) save()
            } 
            if (event.keyCode === 27) restore()
        }
        document.addEventListener("keydown", keyAction);
        return () => document.removeEventListener("keydown", keyAction);
    }, [edit, restore, save, Input]);

    return (
<>
<div key={fID} className="auto-input">
    <div className="flex">
        <Input
            fID={fID}
            label={label}
            value={currValue}
            disabled={!edit}
            handleChange={handleChange}
            options={options}
            error={problem}
            descr={descr}
        />
        {canEdit && !edit &&
            <div className="btn small-btn edit-mode flex tooltip" onClick={() => setEdit(true)}>
                <MdEdit /><span className="tooltiptext">Cliquer pour modifier</span>
            </div>
        }
        {!edit &&
        <>
            <div className="btn small-btn flex tooltip" onClick={() => setHelp(true)}>
                <MdQuestionMark /><span className="tooltiptext">Infos sur ce champ</span>
            </div>
            <HelpPopup label={label} content={descr} show={help} onClose={() => setHelp(false)}/>
        </>
        }
        {canEdit && edit &&
        <>    
            <div className="btn small-btn edit-mode flex tooltip" onClick={save}>
                <MdSave /><span className="tooltiptext">Cliquer pour enregister (ou appuyer sur Entrée)</span>
            </div>
            <div className="btn small-btn edit-mode flex tooltip" onClick={restore}>
                <MdRestore /><span className="tooltiptext">Cliquer pour ré-initialiser (ou appuyer sur Echap)</span>
            </div>
        </>
        }
    </div>
</div>
</>
    );
}