import React from 'react'
import App from "./App"
import { withRouter } from "react-router";

class ErrorCatcher extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    // componentDidCatch(error, info) {
    //     this.setState({ hasError: true })

    //     axios.post(window.baseURL + "/api/errors", {
    //         message: error.message,
    //         url: this.props.location.pathname,
    //         error: JSON.stringify(error, Object.getOwnPropertyNames(error))
    //     })
    // }

    render() {
        if (this.state.hasError) {
            return <>
                <h1>Une erreur est survenue</h1>
                <p>Veuillez contacter le Service Informatique CSSB : service.informatique@cssb.fr</p>
            </>
        }
        return <App />
    }
}

export default withRouter(ErrorCatcher)