import React, { useEffect, useRef } from "react"
import { toast } from 'react-toastify'

export default function DateInput({
    fID, 
    label, 
    value, 
    disabled, 
    handleChange,
    error
}) {

    // Convert date to "YYYY-MM-DD" value
    const date = new Date(value)
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const dateValue = `${year}-${month}-${day}`

	const inputRef = useRef()
	useEffect(() => {
		if(inputRef.current) {
			if (window.lastDateAlert) {
				return
			}
			window.lastDateAlert = Date.now()
			if (inputRef.current.type !== "date")
				toast.error("Votre navigateur ne supporte pas les champs \"date\", "
					+ "veuillez rentrer les dates sous forme : "
					+ "YYYY-MM-DD (ex: 2008-07-03 pour 3 juillet 2008)",
					{ autoClose: 15000 })
		}
		return function () {
			if (window.lastDateAlert) {
				window.lastDateAlert = undefined
			}
		}
	}, [inputRef])

	return (
		<div className={"form-group group-date has-content"
                        + (disabled ? " is-disabled" : "") 
                        + (error ? " has-error" : "")} key={fID}>

			{label &&
				<label htmlFor={"input-" + fID} className="label">{label}</label>
			}

			<input
				ref={inputRef}
				type="date"
				value={dateValue}
				onChange={(e) => handleChange(e.target.value)}
				id={"input-" + fID}
				name={fID}
				disabled={disabled ?? true}
				placeholder={"YYYY-MM-DD"}
			/>

			<div className="baseline"></div>

            <div className="errors">
				{error &&  <div className="error-item"> {error} </div>}
			</div>

		</div>
	)
}