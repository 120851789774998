import React, { useState, useEffect } from "react";
import DashHeader from "../../components/Dash/Header";
import DashSidebar from "../../components/Dash/Sidebar";

const Accounts = props => {
    const [config, setConfig] = useState(null);
    const [scopes, setScopes] = useState(null);
    const [membersData, setMembersData] = useState(null);

    useEffect(() => {
        setConfig(JSON.parse(localStorage.getItem("config")));
        setScopes(JSON.parse(localStorage.getItem("scopes")));
        setMembersData(JSON.parse(localStorage.getItem("membersData")));
    }, []);

    return (
        <div className="admin-page">

            <DashSidebar current="config" />

            <DashHeader paths={[
                {
                    name: "Configuration",
                    url: "/dashboard/config"
                }
            ]} />

            <div className="admin-body">

                <span className="flex flex-horizontal flex-vertical-center flex-space-between">
                    <h2 className="wth-60 ml-15">Configuration de <i>Central</i></h2>
                </span>

                <h3 className="title mb-30 mt-30">Configuration serveur</h3>
                <pre>{JSON.stringify(config, null, 2)}</pre>

                <h3 className="title mb-30 mt-30">Configuration Scopes</h3>
                    {scopes?.BASE && (
                        <table>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>BASE</th>
                                    <th>CHIEF</th>
                                    <th>STAFF</th>
                                    <th>ADMIN</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(scopes.BASE).map((key, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{key}</td>
                                            <td>{scopes.BASE[key]}</td>
                                            <td>{scopes.CHIEF[key]}</td>
                                            <td>{scopes.STAFF[key]}</td>
                                            <td>{scopes.ADMIN[key]}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )}

            <h3 className="title mb-30 mt-30">Configuration Données Membres</h3>
            { membersData?.memberType && (
                <table>
                    <thead>
                        <tr>
                            <th>memberType</th>
                            <th>id</th>
                            <th>type</th>
                            <th>description</th>
                            <th>category</th>
                            <th>default</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(membersData.memberType).map((key, idx) => {
                            return (
                                <tr key={idx}>
                                    {Object.keys(membersData).map((key2, idx2) => {
                                        return (
                                            <td key={idx2}>{membersData[key2][key]}</td>
                                        )
                                    }
                                    )}
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            )}
            </div>
        </div>
    );
};

export default Accounts;
