import { useEffect, useRef } from 'react'

import {
	Switch,
	Route,
	useHistory
} from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Registration pages
import HomePage from 'pages/Home';
import ContactPage from 'pages/Contact';

// Common components
import VersionDisplay from 'components/VersionDisplay';
import ReturnToTop from 'components/ReturnToTop';

// Auth pages
import LoginPage from 'pages/Auth/Login';
import LogoutPage from 'pages/Auth/Logout';
import RequestPasswordReset from 'pages/Auth/RequestPasswordReset';
import ResetPassword from 'pages/Auth/ResetPassword';
import Error404 from 'pages/Error404';

// Dash pages
import DashRoute from 'components/Dash/Route';
import DashHome from "pages/Dash/Home"
import Support from 'pages/Dash/Support';
import Changelog from 'pages/Dash/Changelog';

// Admin Dash pages
import MyData from "pages/Dash/MyData"
import Listing from "pages/Dash/Listing"
// import Trombinoscope from 'pages/Dash/Trombinoscope';
import Member from "pages/Dash/Member"

import AccountList from "pages/Admin/AccountList"
import Account from "pages/Admin/Account"
import AdminConfig from "pages/Admin/Config"

// Events module
import MyEvents     from 'pages/Events/MyEvents';
import Register     from 'pages/Events/Register';
import Overview     from 'pages/Events/Overview';
import Event        from 'pages/Events/Event';
import Registration from 'pages/Events/Registration';

// Medic module
import MedicList  from 'pages/Medic/MedicList';
import MedicEvent from 'pages/Medic/MedicEvent';
import MedicActs  from 'pages/Medic/MedicActs';

// Presence module
import PresenceList  from 'pages/Presence/PresenceList';
import PresenceEvent from 'pages/Presence/PresenceEvent';

function App() {
	
	const history = useHistory()
	const currentPathname = useRef()

	useEffect(() => {
		const unregisterHistoryListener = history.listen((newLocation, action) => {
			if (action === "PUSH") {
				if (newLocation.pathname !== currentPathname.current) {
					// Save new location
					currentPathname.current = newLocation.pathname
					if (newLocation.pathname.startsWith("/register/")) {
						// Clone location object and push it to history
						history.push({
							pathname: newLocation.pathname
						});
					}
				}
			} else if(action === "POP") {
				if (window.blockBackBtn) {
					history.go(1)
				}
			}
		})
		return () => unregisterHistoryListener()
	}, [history])

	return (
		<>
			<VersionDisplay />

			<Switch>

				{/* Public pages */}
			    <Route exact path="/" component={HomePage} /> 
				<Route exact path="/contact" component={ContactPage} />

				{/* Access */}
				<Route exact path="/login" component={LoginPage} />
				<Route exact path="/logout" component={LogoutPage} />
				<Route exact path="/request-password-reset" component={RequestPasswordReset} />
				<Route exact path="/reset-password/:token" component={ResetPassword} />

				{/* Main dashboard (base user module)*/}
				<DashRoute exact path="/dashboard" component={DashHome} />
                <DashRoute exact path="/dashboard/support" component={Support} />
                <DashRoute exact path="/dashboard/changelog" component={Changelog} />

				<DashRoute exact path="/dashboard/my-data" component={MyData} />
				<DashRoute exact path="/dashboard/my-data/:matricule" component={Member}/>

                <DashRoute exact path="/dashboard/my-events" component={MyEvents} />
                <DashRoute exact path="/dashboard/my-events/:eID" component={Register} />

                {/* Event module */}
                <DashRoute exact path="/dashboard/events" component={Overview} />
                <DashRoute exact path="/dashboard/events/:eID" component={Event} />
                <DashRoute exact path="/dashboard/events/:eID/:matricule" component={Registration} />
                
				{/* Listing module */}
				<DashRoute exact path="/dashboard/listing" component={Listing} />
				<DashRoute exact path="/dashboard/listing/:matricule" component={Member}/>

				{/* Trombinoscope */}
				{/* <DashRoute exact path="/dashboard/trombinoscope" component={Trombinoscope} /> */}
                
                {/* Medic module */}
                <DashRoute exact path="/dashboard/medic" component={MedicList} />
                <DashRoute exact path="/dashboard/medic/:eID" component={MedicEvent} />
                <DashRoute exact path="/dashboard/medic/:eID/acts" component={MedicActs} />
                <DashRoute exact path="/dashboard/medic/:eID/:matricule" component={Member} />

                {/* Presence module */}
                <DashRoute exact path="/dashboard/presence" component={PresenceList} />
                <DashRoute exact path="/dashboard/presence/:eID" component={PresenceEvent} />
                <DashRoute exact path="/dashboard/presence/:eID/:matricule" component={Member} />

				{/* Account module (only DEV access for now)*/}
				<DashRoute exact path="/dashboard/accounts" component={AccountList} />
				<DashRoute exact path="/dashboard/accounts/:accountId" component={Account} />

				{/* Dev module */}
				<DashRoute exact path="/dashboard/config" component={AdminConfig} />

				{/* Undefined path */}
				<Route component={Error404} />
					
			</Switch>

            <ReturnToTop />

			<ToastContainer />

		</>
	);
}

export default App;
