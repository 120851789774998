import React, { useRef, useState } from "react"

function DropdownMenu({ header, content }) {

	const menuRef = useRef(null)

	const [height, setHeight] = useState(0)

	const closeMenu = () => {
		setHeight(0)
		window.removeEventListener("click", closeMenu)
	}

	const handleHeaderClick = e => {
		e.stopPropagation()
		if(height === 0) {
			setHeight(menuRef.current.offsetHeight)
			window.addEventListener("click", closeMenu)
		} else {
			closeMenu()
		}
	}

	return (
		<div className="dropdown-menu">
            
			<div className="dropdown-header" onClick={handleHeaderClick}>{header((height > 0))}</div>
			<div className="dropdown-content" style={{ height: height + "px" }}>
				<div ref={menuRef}>{content((height > 0))}</div>
			</div>
		</div>
	)
}

export default DropdownMenu