import React, { useState } from 'react';

import { CHECK_POST_ADDRESS } from 'macros';
import { realDiscount } from 'utils/events';

function HelloAssoInfo({
    data,
    infoOnly=false,
}) {
    function openHelloAssoPage() {
        const win = window.open(data.link, '_blank', 'noopener,noreferrer');
        if (win) { win.focus(); }
    }

    const codes = data.discounts.map(d => d.code)

    return (
<div className="paymentMode">
    <strong>Paiement choisi via HelloAsso</strong>
    <div className="paymentModeDescr">
        { data.amount ?
        <div>
        Montant du paiement attendu : {data.amount}€ {data.discount}
        </div> :
        <div>
        Montant variable, indiqué sur la page de payement HelloAsso
        </div>
        }
        {codes.length > 0 && 
        <ul>
            {codes.map((code, idx) => 
                <li key={idx}> code PROMO à utiliser sur HelloAsso pour la réduction : {code} </li>
            )}
        </ul>}
        {codes.length > 1 &&
        <div><strong>Attention !</strong> HelloAsso ne permet pas d'utiliser plusieurs codes PROMO lorsque vous payez 
        ... et il se trouve que plusieurs réductions vous sont appliquées. 
        Veuillez donc contacter le Service Informatique CSSB (par mail : service.informatique@cssb.fr, ou via le formulaire de support),
        afin qu'un code promo spécifique reprenant les deux réductions puisse être généré pour vous. 
        </div>
        }
        {!infoOnly &&
        <>
        L'inscription sera automatiquement validée lorsque le paiement sera reçu par HelloAsso 
        (pensez à bien rentrer lors du paiement le matricule du ou des membres concernés)
        <div className="register-btn-group">
            <button className="btn" onClick={openHelloAssoPage}> Payer sur HelloAsso </button>
        </div>
        </>
        }
    </div>
</div>
    )
}

function CheckInfo({
    data,
    infoOnly=false,
}) {

    return (
<div className="paymentMode">
    <strong>Paiement choisi par chèque(s)</strong> (à l'ordre de "CSSB")
    <div className="paymentModeDescr">
        <div style={{marginBottom: "6px"}}>
        Montant du paiement attendu : {data.amount}€ {data.discount}
        </div>
        {!infoOnly && <>
        L'inscription sera automatiquement validée par un membre administratif lorsque vos chèques seront reçu à l'adresse suivante :
        <ul>
            <li> {CHECK_POST_ADDRESS} </li>
        </ul>
        </>}
    </div>
    {!infoOnly && <>
    <strong>Important :</strong> n'oubliez pas de préciser au dos des chèques le ou les matricules des membres concernés par le paiement 
        (et les dates d'encaissement différées pour les paiements en plusieurs fois).
    </>}
    <div style={{marginTop: "6px"}}>
        Banque : {data.bank}, 
        montant chèque n°1 : {data.amounts[0]}€,
        montant chèque n°2 : {data.amounts[1]}€,
        montant chèque n°3 : {data.amounts[2]}€
    </div>
</div>
    )
}

function VacationCheckInfo({
    data,
    infoOnly=false,
}) {

    return (
<div className="paymentMode">
    <strong>Paiement choisi par chèque vacance</strong> (à l'ordre de "CSSB")
    <div className="paymentModeDescr">
        <div style={{marginBottom: "6px"}}>
        Montant du paiement attendu : {data.amount}€ {data.discount}
        </div>
        {!infoOnly && <>
        L'inscription sera automatiquement validée par un membre administratif lorsque le chèque vacance (avec le complément éventuel) 
        sera reçu à l'adresse suivante
        <ul>
            <li> {CHECK_POST_ADDRESS} </li>
        </ul>
        </>}
    </div>
    {!infoOnly && <>
    <strong>Important :</strong> n'oubliez pas d'indiquer avec le chèque vacance le ou les matricules des membres concernés par le paiement.
    <div style={{marginTop: "6px"}}>
        Montant du chèque vacance : {data.mainAmount}€, complément : {data.complement}€ 
    </div>
    </>}
</div>
    )
}

function SponsorInfo({
    data,
    infoOnly=false,
}) {

    const [copied, setCopied] = useState(false)
    const url = "https://foreveryoungfoundation.fr/associations/secourisme-cssb/"

    const copyToClipboard = () => {
        navigator.clipboard.writeText(url)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 2000) // Reset the 'copied' state after 2 seconds
    };

    return (
<div className="paymentMode">
    <strong>Paiement choisi par parrainage</strong>
    <div className="paymentModeDescr">
        <div style={{marginBottom: "6px"}}>
        Montant du paiement attendu : {data.amount}€ {data.discount}
        </div>
        {!infoOnly && <>
        L'inscription sera automatiquement validée par un membre administratif lorsque le paiement sera reçu sur le site Forever Young Foundation :
        <ul>
            <li> 
                <a href={url} target="_blank" rel="noopener noreferrer">
                    {url}
                </a>
                <button style={{marginLeft: "12px"}} onClick={copyToClipboard}>
                    {copied ? 'Copié!' : 'Copier le lien'}
                </button>
            </li>
        </ul>
        <strong>Merci d'envoyer ce lien au parrain ...</strong> avec le montant du paiement attendu (car nous n'avons pas son contact ;)
        </>}
    </div>
    <div style={{marginTop: "6px"}}>
        Identité indiquée pour le parrain : {data.sponsor}
    </div>
</div>
    )
}

export default function PaymentInfo({
    data,
    infoOnly=false,
}) {
    const [discount, amount] = realDiscount(data.discounts, data.amount, data.mode)
    console.log(data.discounts, data.amount, data.mode)
    const Infos = {
        "helloAsso": HelloAssoInfo,
        "check": CheckInfo,
        "vacationCheck": VacationCheckInfo,
        "sponsor": SponsorInfo,
    }[data.mode]

    return <Infos data={{...data, amount:amount, discount:discount}} infoOnly={infoOnly}/>
}