import React, { useEffect, useRef } from "react"

export default function TextareaInput({
    fID,
    label,
    value,
    disabled,
    handleChange,
    error,
    descr,
}) {

    const textareaRef = useRef(null);
    useEffect(() => {
        textareaRef.current.style.height = "auto"
        if (textareaRef.current) {
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
      }, [value]);

	return (
<div className={"form-group has-content group-textarea"
                + (disabled ? " is-disabled" : "")
                + (error ? " has-error" : "")} key={fID}>

    <label htmlFor={"input-" + fID} className="label">{label}</label>

    <textarea
        ref={textareaRef}
        placeholder=""
        id={"input-" + fID}
        name={fID}
        onChange={(e) => handleChange(e.target.value)}
        value={value ?? ""}
        disabled={disabled ? disabled : false}
        rows={1}
    />

    <div className="baseline"></div>

    {error &&
    <div className="errors">
        <div className="error-item"> {error} </div>
    </div>
    }

</div>
	)
}