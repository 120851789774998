import React, { useState, useRef } from "react"
import axios from "axios"
import { toast } from "react-toastify"

import AuthService from "services/AuthService"

import DashHeader from 'components/Dash/Header'
import DashSidebar from 'components/Dash/Sidebar'
import TextareaInput from "components/Inputs/OldTextarea"
import FileInput from "components/Inputs/File"
import FullPageLoading from "components/FullPageLoading"

import Validations from "utils/validations"

import { Collapsible } from "components/utils/dev"

export default function Support() {

    const user = useRef(AuthService.getCurrentUser())

    const [formData, setFormData] = useState({...user.current})
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    
    // Small trick to force reload component after submit
    const [seed, setSeed] = useState(1)
    const resetInput = () => setSeed(Math.random())
    
    function handleFormChange(event) {
        // target is the input, which need a name attribute
		setFormData({ ...formData, [event.target.name]: event.target.value })
        setErrors({})
	}

    function handleSubmit(event) {
		event.preventDefault()
        setLoading(true)

        const msgError = Validations.validString(formData.message, { minLength: 3, maxLength: 500 })
        if (msgError) {
            setErrors({"message": [msgError]})
            setLoading(false)
            return
        }

        const reqForm = new FormData()
        for (const key in formData) {
            reqForm.append(key, formData[key])
        }

		axios.post("/api/accounts/help", reqForm
        ).then(res => {
			toast.success(res.data.status)
            setFormData({...user.current})
            resetInput()
		}).catch(error => {
			if (error?.response?.data.detail) {
				toast.error(JSON.stringify(error.response.data.detail))
			} else {
				toast.error("Erreur bizarre ...")
				console.log("erreur", error)
			}
		}).finally(() => {
            setLoading(false)
        })
	}

	return (
<div className="admin-page">
    <FullPageLoading show={loading} />

    <DashSidebar current="support" />

    <DashHeader paths={[
        {
            name: "Support",
            url: "/dashboard/support"
        }
    ]} />

    <div className="admin-body">
        <div className="member">

            <section className="member-basic">
                <div className="info-section">
                    Avec le formulaire ci-dessous, vous pouvez :
                    <ul>
                        <li> demander une modification de données personnelles que vous ne pouvez pas faire, </li>
                        <li> rapporter un bug ou suggérer une amélioration du site internet, </li>
                        <li> transmettre tout votre mécontentement vis-à-vis de ce nouveau système, </li>
                    </ul>
                    ... ou tout simplement nous envoyer un message d'encouragement (nous acceptons tout ;)
                    <br></br>
                    <br></br>
                    Ce formulaire sera transmis directement par mail à plusieurs personnes responsables.
                </div>
            </section>

            <section className="member-basic">

            <div className="section-header flex flex-space-between">
                <div className="section-title">Que voulez-vous nous dire ?</div>
            </div>

                <div className="section-content" key={seed}>

                    <div className="flex flex-space-around">
                        <TextareaInput
                            label="Message ..."
                            name="message"
                            onChange={handleFormChange}
                            value={formData.message}
                            errors={errors.message}
                        />

                        <FileInput
                            className="mt-30 supportForm-text"
                            title="Fichier (facultatif)"
                            name="file"
                            subtitle="Fichier spécifique, capture d'écran ..."
                            fileExt={[".png", ".jpg", ".jpeg"]}
                            onChange={handleFormChange}
                            value={formData.file}
                            maxSize={5 * 1024 * 1024}
                        />
                    </div>

                    <div className="form-btn-group">
                        <button className="btn" onClick={handleSubmit}>Envoyer</button>
                    </div>

                </div>
            </section>

            {/* For development and debug ... */}
            <Collapsible header="formData" data={formData} />

        </div>
    </div>

    

</div>
	)
}