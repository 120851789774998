import React, { useState, useEffect } from "react";
import axios from "axios";

import DashHeader from "components/Dash/Header";
import DashSidebar from "components/Dash/Sidebar";

import Table from "components/Table"
import { Collapsible } from "components/utils/dev";
import CreateAccountPopup from "components/Admin/CreateAccountPopup";


function AccountList() {
    const [accounts, setAccounts] = useState([]);
    const [showCreateMember, setShowCreateMember] = useState(false);

    useEffect(() => {
        const fetchAccounts = async () => {
            const response = await axios.get("/api/accounts/list");
            setAccounts(response.data);
        };
        fetchAccounts();
    }, [showCreateMember]);

    // const handleCreateMember = () => {
    //     setShowCreateMember(true);
    // };

    const allProfiles = [...new Set(accounts.map(account => account.profiles))]

    return (
<div className="admin-page">

<DashSidebar current="accounts" />

<DashHeader paths={[
    {
        name: "Comptes Utilisateurs",
        url: "/dashboard/accounts"
    }
]} />

<div className="admin-body">

    {/* <span className="flex flex-horizontal flex-vertical-center flex-space-between">
        <div className="wth-60 ml-15"></div>
        <div className="btn" onClick={handleCreateMember}>+ Nouveau membre</div>
    </span> */}

    <Table
        uniqueID="accounts"
        headers={[
            {
                label: "Email",
                key: "email"
            },
            {
                label: "Matricules",
                key: "matricules",
                formatFunc: (matricules) => matricules.join(", ")
            },
            {
                label: "Profiles",
                key: "profiles"
            },
            {
                label: "Dernière Connection",
                key: "lastConnect"
            },
        ]}
        searchKeys={["email", "matricules", "lastConnect"]}
        filters={[{
            "key": "profiles",
            "label": "Profiles",
            "options": allProfiles.map(value => ({name:value, value:value}))
        }]}
        dataName="compte"
        data={accounts}
    />

    {showCreateMember && (
        <CreateAccountPopup
            onReject={() => setShowCreateMember(false)}
            onConfirm={() => setShowCreateMember(false)}
        />
    )}

    {/* For development and debug ... */}
    <Collapsible header="accounts" data={accounts} />

</div>

</div>
    );
};

export default AccountList;
