import React from "react"

import '../styles/loading.css'

const FullPageLoading = props => {
	return (
		<div className={"full-page-loading" + (props.show ? " is-visible" : "")}>
			<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
		</div>
	)
}

export default FullPageLoading