import axios from 'axios'
import React, { useRef, useState } from "react"
import { getFileTypeIcon, ActionButton as Button } from '../../icons'

import './FileDisplay.css'
import { toast } from 'react-toastify'
import ConfirmPopup from '../ConfirmPopup'
import FullPageLoading from '../FullPageLoading'
import { HelpPopup } from 'components/utils/doc'

const { detect } = require('detect-browser');
const browser = detect();

export default function FileDisplay({
    matricule, 
    fID,
    field,
    problems,
    statusNonOK,
    updateProblems,
}) {
    const { label, descr, scope=0, value } = field

	// State concerning loading
	const [modal, setModal] = useState("")
	const [isLoading, setIsLoading] = useState(false)

    // State about the help button
    const [help, setHelp] = useState(false)

	// State concerning the file (comes from parent)
	const [fileData, setFileData] = useState(value)
	const [FileIcon, setFileIcon] = useState(getFileTypeIcon(value?.ext ?? ""))

    const problem = problems ? problems[fID] : ""
	const fileInput = useRef()

	const fileName = `${fID}.${fileData ? fileData.ext : ""}`

	function downloadFile() {
		if (!fileData?.ext) return
		setIsLoading(true)
		axios.get(`/api/files/${matricule}/${fID}`, { responseType: "blob" }).then(res => {

			const url = window.URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement('a')
			link.href = url
			switch (browser && browser.name) {
				case 'firefox':
					link.target = "_blank"
					link.rel = "noopener noreferrer"
					break;
				default:
					link.download = fileName
				  	break;
			}
			link.click()
			setIsLoading(false)

		}).catch(err => {
			setIsLoading(false)
			console.log(err)
		})
	}

	function uploadFile() {
		setIsLoading(true)
		setModal("")

		let file = fileInput.current.files[0]

		if (!file)
			return toast.error("Vous devez sélectionner un fichier")

		let fileForm = new FormData()

		fileForm.append("file", file)

		axios.put(`/api/files/${matricule}/${fID}`, fileForm
        ).then(res => {
			toast.success("Le fichier à bien été "+(fileData ? "modifié" : "ajouté"))
			setFileData(res.data)
			if (res.data) {
				setFileIcon(getFileTypeIcon(res.data.ext))
                if (res.data.problem) {
                    updateProblems({...problems,[fID]:res.data.problem})
                }
			}
		}).catch(error => {
			if (error?.response?.data.detail) {
				toast.error(error.response.data.detail)
			} else {
				toast.error("Erreur bizarre ...")
				console.log(error)
			}
		}).finally(() => {
			setIsLoading(false)
		})
	}

	function handleUpload(e) {
		let file = e.target.files[0]
		if(!file)
			return toast.error("Vous devez sélectionner un fichier")
		if (fileData?.ext)
			setModal("confirmEdit")
		else
			uploadFile()
	}
	function handleEdit(e) { e.stopPropagation(); fileInput.current.click() }

	function updateFileData(problem) {
		setIsLoading(true)

		axios.post(`/api/files/${matricule}/${fID}`, {
			"problem": problem,
            "statusNonOK": statusNonOK,
		}).then(res => {
			toast.success("Les détails du fichier ont bien été mis à jour")
			setFileData(res.data.fData)
			setFileIcon(getFileTypeIcon(res.data.fData.ext))
            updateProblems({...problems, [fID]:res.data.fData.problem})
            if (res.data.email) { toast.success(res.data.email) }
		}).catch(err => {
			if (err?.response?.data?.detail) {
				toast.error(err.response.data.detail)
			} else {
				toast.error("Erreur bizarre ...")
				console.log(err)
			}
		}).finally(() => {
			setIsLoading(false)
		})
	}
	function handleValid(e) { e.stopPropagation(); updateFileData() }
	function handleReport(e) { e.stopPropagation(); setModal("reportFile") }

	function deleteFile() {

		setModal("")
		setIsLoading(true)

		axios.delete(`/api/files/${matricule}/${fID}`).then(res => {
			setIsLoading(false)
			toast.success("Le fichier à bien été supprimé")
			setFileData(res.data)
			setFileIcon(getFileTypeIcon(""))

		}).catch(err => {
			setIsLoading(false)

			if (err.response && err.response.data.error) {
				toast.error(err.response.data.error)
			} else {
				console.log(err)
				toast.error("Une erreur est survenue")
			}
		})
	}
	function handleDelete(e) { e.stopPropagation(); setModal("confirmDelete"); }

    // In case no reading right, don't render anyway (server shouldn't send data with scope == 0 anyway)
	if (scope === 0) return null
	return (
<>
{modal === "confirmEdit" &&
    <ConfirmPopup 
        title="Voulez-vous vraiment modifier ce fichier ? L'ancien fichier sera supprimé." 
        onConfirm={uploadFile} 
        onReject={() => setModal("")} 
    />
}
{modal === "confirmDelete" &&
    <ConfirmPopup 
        title="Voulez-vous vraiment supprimer ce fichier ?" 
        onConfirm={deleteFile} 
        onReject={() => setModal("")} 
    />
}
{modal === "reportFile" &&
    <ConfirmPopup 
        title="Signaler un problème avec ce fichier ?"
        withComment={true}
        onConfirm={(msg) => { updateFileData(msg); setModal("")} } 
        onReject={() => setModal("")} 
    />
}
<FullPageLoading show={isLoading} />

<div key={fID} 
        className={"file-display" + (fileData?.ext ? "" : " no-file") + (problem ? " file-problem" : "")} 
    >
    
    <div className="icon" onClick={downloadFile}>
        <FileIcon />
    </div>

    <div className="file-infos">
        <div>{label ?? fID}</div>

        <div className="flex">
            <Button 
                type="help" 
                onClick={(e) => {e.stopPropagation(); setHelp(true)}} 
                tooltip={"Infos sur ce fichier"}
            />
            <HelpPopup label={label} content={descr} show={help} onClose={() => setHelp(false)}/>

            {/* Scope at least 2 to upload a file if not there */}
            {scope > 1 && !fileData?.ext &&
                <Button type="upload" onClick={handleEdit} tooltip={"Téléverser un fichier"}/>
            }

            {/* Scope at least 2 to modify an existing file */}
            {scope > 1 && fileData?.ext && 
                <Button type="edit" onClick={handleEdit} tooltip={"Modifier le fichier"}/>
            }

            {/* Scope at least 3 to validate an existing file, if there is a problem */}
            {scope > 2 && fileData?.ext && problem &&
                <Button type="valid" onClick={handleValid} tooltip={"Valider le fichier"}/>
            }

            {/* Scope at least 3 to report an existing file */}
            {scope > 2 && fileData?.ext &&
                <Button type="report" onClick={handleReport} tooltip={"Signaler un problème"}/>
            }
            
            {/* Scope at least 4 to be able to delete a file (server side : only DEV can) */}
            {scope > 3 && fileData?.ext &&
                <Button type="delete" onClick={handleDelete} tooltip={"Supprimer le fichier"}/>
            }
        </div>
    </div>
    <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", rowGap: "10px"}}>
        {fileData?.date &&
            <div>Mis à jour le {fileData.date}</div>
        }
        {fileData?.valid &&
            <div>Validé le {fileData.valid}</div>
        }
        {problem && 
            <div>Info : {problem}</div>
        }
    </div>
    
    <input type="file" ref={fileInput} onClick={(e) => e.stopPropagation()} onChange={handleUpload} style={{ display: "none" }} />
</div>
</>
	)
}