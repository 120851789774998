function MemberDisplay({account, matricule}) {

    return (
        <>
            <h1>Informations for {matricule}</h1>
            <div className="companion-name">
                {account.firstName} {account.lastName} <br />
            </div>

            {account.company}
            {account.emails.map((email, idx) => (
                <div key={idx}>
                    {email}
                </div>
            ))}
        </>
    )
}

export default MemberDisplay