import React, { useCallback, useEffect, useRef, useState } from "react"

import { BsFileEarmarkArrowUp } from "react-icons/bs"
import { FiUpload } from "react-icons/fi"
import { toast } from "react-toastify"

let draggingCounter = 0

const FileInput = props => {

	const hiddenFileInput = useRef(null)
	const groupFileDiv = useRef(null)

	const [isValid, setIsValid] = useState(false)
	const [dragging, setDragging] = useState(false)
	const [currentFilename, setCurrentFilename] = useState(props.subtitle)

	const handleClick = e => {
		if(hiddenFileInput.current)
			hiddenFileInput.current.click()
	}

	const filenameValid = useCallback(filename => {

		let ext = filename.split('.').pop().toLowerCase();

		if(props.fileExt && props.fileExt.includes("." + ext)) {
			setIsValid(true)
			return true
		}
		else {
			if(!props.fileExt) {
				setIsValid(true)
				return true
			}
			else {
				setIsValid(false)
				return false
			}
		}
	}, [props.fileExt])

	const sizeValid = useCallback(fileSize => {

		if(!props.maxSize)
			return true

		if(fileSize <= props.maxSize)
			return true

		return false

	}, [props.maxSize])

	const handleFileChange = file => {
		if (filenameValid(file.name)) {

			if(sizeValid(file.size)) {

				setCurrentFilename(file.name)

				if (props.onChange) {
					props.onChange({
						"target": {
							"name": props.name,
							"value": file
						}
					})
				}

			} else {
				toast.error("Le fichier est trop volumineux")
			}

		} else {
			toast.error("Le nom du fichier n'est pas valide")
		}
	}

	const reloadDraggingState = () => {
		if(draggingCounter > 0)
			setDragging(true)
		else
			setDragging(false)
	}

	const handleChange = event => {
		const fileToUpload = event.target.files[0]
		if (fileToUpload) {
			handleFileChange(fileToUpload)
		}

	}

	const handleDragIn = useCallback(e => {
		e.preventDefault()
		  e.stopPropagation()

		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			draggingCounter++
			reloadDraggingState()
		}
	}, [])

	const handleDragOut = useCallback(e => {
		e.preventDefault()
		e.stopPropagation()

		draggingCounter--

		reloadDraggingState()
	}, [])

	const handleDrag = useCallback(e => {
		e.preventDefault()
		e.stopPropagation()
	}, [])

	const handleDrop = useCallback(e => {
		e.preventDefault()
		e.stopPropagation()

		draggingCounter = 0
		reloadDraggingState()

		let fileToUpload = e.dataTransfer.files[0]
		if (e.dataTransfer.files && fileToUpload) {
			handleFileChange(fileToUpload)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filenameValid, props])

	useEffect(() => {
		if(props.value)
			setCurrentFilename(props.value.name)
		else
			setCurrentFilename(props.subtitle)
	}, [props.value, props.subtitle])

	useEffect(() => {
		if(props.errors)
			setIsValid(false)
		else
			setIsValid(props.value ? true : false)
	}, [props.errors, props.value])

	useEffect(() => {

		let div = groupFileDiv.current

		div.addEventListener('dragenter', handleDragIn)
	    div.addEventListener('dragleave', handleDragOut)
	    div.addEventListener('dragover', handleDrag)
		div.addEventListener('drop', handleDrop)

		return function () {
			div.removeEventListener('dragenter', handleDragIn)
		    div.removeEventListener('dragleave', handleDragOut)
		    div.removeEventListener('dragover', handleDrag)
		    div.removeEventListener('drop', handleDrop)
		}

	}, [handleDragIn, handleDrag, handleDragOut, handleDrop])

	return (
		<div ref={groupFileDiv} className={"form-group group-file" + (isValid ? " is-valid" : "") + (props.errors ? " has-error" : "") + (props.className ? " " + props.className : "")} onClick={handleClick}>

			{dragging > 0 &&
				<div className="dragging-info">Relachez</div>
			}

			<div className="progress" style={{ width: "0%" }}></div>

			<div className="file-icon"><BsFileEarmarkArrowUp size={42} /></div>

			<div className="file-label">
				<div className="title">{props.title}</div>
				<div className="subtitle">{currentFilename}</div>
			</div>

			<div className="add-file">
				<div className="upload-icon"><FiUpload size={24} /></div>
				<div className="upload-label">{isValid ? "Remplacer" : "Ajouter"}</div>
			</div>

			<input
				name={props.name}
				category={props.category}
		        type="file"
		        ref={hiddenFileInput}
		        onChange={handleChange}
				style={{ display: 'none' }}
				accept={props.fileExt ? props.fileExt.join(',') : ""}
	      	/>

			<div className="errors">

				{props.errors && props.errors.map((error, idx) => {
					return (
						<div key={idx} className="error-item">
							{error}
						</div>
					)
				})}

			</div>

		</div>
	)
}

export default FileInput