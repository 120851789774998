import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

import DashHeader from "components/Dash/Header";
import DashSidebar from "components/Dash/Sidebar";
import { ListingInfos } from "components/Dash/Infos"

import Table from "components/Table";
import CreateMember from "components/Dash/CreateMember";
import { Collapsible } from "components/utils/dev";
import FullPageLoading from "components/FullPageLoading";

export default function Listing ({
    history, // Note : history is implicitly passed as component argument
}) {
    const [headers, setHeaders] = useState([])
    const [members, setMembers] = useState([])
    const [searchKeys, setSearchKeys] = useState([])
    const [filters, setFilters] = useState([])
    const [addRights, setAddRights] = useState({})

    const [isLoading, setIsLoading] = useState(false)

    // Dummy comment to check something ...
    const loadListing = useCallback(() => {
        setIsLoading(true)
        axios.get("/api/members/list"
        ).then(res => {
            setHeaders(res.data.headers);
            setMembers(res.data.members);
            setSearchKeys(res.data.searchKeys);
            setFilters(res.data.filters)
            setAddRights(res.data.addRights ?? {})
        }).catch(err => {
            console.log("Error ", err)
        }).finally(() => setIsLoading(false))
    }, [])

	useEffect(() => {
        loadListing()
    }, [loadListing]);

    return (
<div className="admin-page">

    <FullPageLoading show={isLoading}/>

    <DashSidebar current="listing" />
    <DashHeader paths={[{ name: "Listing", url: "/dashboard/listing" }]}/>

    <div className="admin-body">

        <CreateMember addRights={addRights} onSuccess={loadListing}/>
        
        <ListingInfos/>
        
        {headers && members &&
        <Table
            uniqueID="listing"
            headers={headers}
            data={members}
            filters={filters}
            searchKeys={searchKeys}
            onItemClicked={member => history.push(`/dashboard/listing/${member.matricule}`)}
        />}

        {/* For development and debug ... */}
        <Collapsible header="headers" data={headers} />
        <Collapsible header="members" data={members} />
        <Collapsible header="searchKeys" data={searchKeys} />
        <Collapsible header="filters" data={filters} />
        <Collapsible header="addRights" data={addRights} />

    </div>

</div>
    );
};
