import React, { useState } from "react"
import { Link } from 'react-router-dom'

const tabs = [
	{
		name: "Accueil",
		slug: "home",
		path: "/dashboard"
	},
	{
		type: "separator"
	},
    // Data related modules
	{
		name: "Mes Données",
		slug: "my-data",
		path: "/dashboard/my-data",
	},
    {
		name: "Listing",
		slug: "listing",
		path: "/dashboard/listing",
		permission: profiles => profiles.length > 0,
	},
	// {
	// 	name: "Trombinoscope",
	// 	slug: "trombinoscope",
	// 	path: "/dashboard/trombinoscope",
	// 	permission: profiles => profiles.length > 0,
	// },
	{
		type: "separator"
	},
    // Even related modules
    {
		name: "S'inscrire",
		slug: "my-events",
		path: "/dashboard/my-events",
	},
    {
		name: "Évènements",
		slug: "events",
		path: "/dashboard/events",
        permission: profiles => profiles.length > 0,
	},
    {
		name: "Suivi Médical",
		slug: "medic",
		path: "/dashboard/medic",
        permission: profiles => profiles.includes("DEV") || profiles.includes("MEDIC"),
	},
    {
		name: "Présences",
		slug: "presence",
		path: "/dashboard/presence",
        permission: profiles => profiles.includes("DEV") || profiles.some(elt => elt.startsWith("STAFF"))
	},
    {
		type: "separator"
	},
    // Technic related modules
    {
		name: "Support",
		slug: "support",
		path: "/dashboard/support",
	},
	{
		name: "Accès",
		slug: "accounts",
		path: "/dashboard/accounts",
		permission: profiles => profiles.includes("DEV"),
	},
    {
		name: "Mises à jour",
		slug: "changelog",
		path: "/dashboard/changelog",
	},
	{
		type: "separator"
	},
	{
		name: "Déconnexion",
		slug: "logout",
		path: "/logout"
	}
]

function DashSidebar ({ current }) {

	const [dropdownOpened, setDropdownOpened] = useState("")

	const { profiles } = JSON.parse(localStorage.getItem("user"))

	const toggleDropdown = (dropdownName) => {
		if(dropdownOpened === dropdownName)
			setDropdownOpened("")
		else
			setDropdownOpened(dropdownName)
	}

	return (
<div className="admin-sidebar">

    <Link to="/" className="sidebar-title">CSSB</Link>

    <nav className="sidebar-nav">

        {tabs.map((tab, idx) => {

            if(tab.type) {
                if(tab.type === "separator") {
                    return <div key={idx} className="separator"></div>
                }
            }

            if(tab.permission && !tab.permission(profiles))
                return null

            if(tab.dropdown) {
                return (
                    <div key={idx} className={"sidebar-dropdown-menu" + ((dropdownOpened === tab.slug || current === tab.slug) ? " is-opened" : "")}>
                        <Link to={tab.path} className="dropdown-menu-title" onClick={(e) => toggleDropdown(tab.slug)}>{tab.name}</Link>
                        <div className="dropdown-menu-items">
                            {tab.children.map((child, idx) => {
                                return (
                                    <Link to={child.path} key={100+idx} className="sidebar-nav-item dropdown-menu-item">{child.name}</Link>
                                )
                            })}
                        </div>
                    </div>
                )
            }

            return (
                <Link key={idx} to={tab.path} className={"sidebar-nav-item" + (current === tab.slug ? " is-selected" : "")}>{tab.name}</Link>
            )
        })}

    </nav>

</div>
	)
}

export default DashSidebar