import React, { useEffect, useState } from "react"

import { IoIosArrowDown } from "react-icons/io"

export default function SelectInput (
	{fID, label, value, disabled, handleChange, options, error}) {

	function findOption(value) {
		return options.find( elem => (elem.value === value) )
	}

	const [isFocused, setIsFocused] = useState(false)
	const selectedOption = findOption(value)

	function handleInputClick(e) {
		if(disabled) return
		e.stopPropagation()
		setIsFocused(!isFocused)
	}

	function handleOptionClick (e) {
		e.stopPropagation()
		setIsFocused(false)
		let { target } = e
		let clickedOptionIdx = target.attributes["data-idx"].nodeValue
		let newSelectedOption  = options[clickedOptionIdx]
		handleChange(newSelectedOption.value)
	}

	useEffect(() => {
		document.addEventListener("click", () => setIsFocused(false))
		return function() {
			document.removeEventListener("click", () => setIsFocused(false))
		}
	}, [])

	return (
		<div className={"form-group group-select has-content"
						+ (error ? " has-error" : "") 
						+ (isFocused ? " is-focused" : "")} key={fID}>

			<label className="label">{label}</label>

			<div className="select-input" name={fID}>

				<div className="placeholder-values">
					<div className="placeholder-values-item">{label}</div>
					{options.map((option, idx) => {
						return (
							<div key={idx} className="placeholder-values-item">{option.label}</div>
						)
					})}
				</div>

				<div className="selected-value" 
					onClick={handleInputClick}>{selectedOption ? selectedOption.label : value}
				</div>

				<div className="select-options">
					<div className="to-slide">
						{options.map((option, idx) => {
							if(selectedOption && option.value === selectedOption.value) return ""
							return (
								<div className="option-item" key={idx} 
									onClick={handleOptionClick} 
									data-idx={idx} 
									data-value={option.value}
								>
									{option.label}
								</div>
							)
						})}
					</div>
				</div>

			</div>

			<div onClick={handleInputClick} className="input-icon">
				{!disabled &&
					<IoIosArrowDown />
				}
			</div>

			<div className="baseline"></div>

            <div className="errors">
                {error && <div className="error-item">{error}</div> }
            </div>

		</div>
	)
}