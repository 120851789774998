import React from 'react';

import { CHECK_POST_ADDRESS } from 'macros';

function HelloAssoMode({
    data,
}) {
    function openHelloAssoPage() {
        const win = window.open(data.link, '_blank', 'noopener,noreferrer');
        if (win) { win.focus(); }
    }

    return (
<div className="paymentMode">
    <strong>Paiement via HelloAsso</strong>
    <div className="paymentModeDescr">
        <div>
            Aucun information spécifique demandée
        </div>
        <div className="register-btn-group">
            <button className="btn" onClick={openHelloAssoPage}> Voir la page HelloAsso </button>
        </div>
        
    </div>
</div>
    )
}

function CheckMode() {
    return (
<div className="paymentMode">
    <strong>Paiement par chèque(s)</strong> (à l'ordre de "CSSB")
    <div className="paymentModeDescr">
        Informations demandées :
        <ul>
            <li>Nom de la Banque</li>
            <li>Montant du chèque n°1</li>
            <li>Montant du chèque n°2 (facultatif)</li>
            <li>Montant du chèque n°3 (facultatif)</li>
        </ul>
        La somme du montant des chèques doit être égale au prix total demandé. Adresse d'envoi :
        <ul>
            <li> {CHECK_POST_ADDRESS} </li>
        </ul>
    </div>
</div>
    )
}

function VacationCheckMode() {

    return (
<div className="paymentMode">
    <strong>Paiement par chèque vacance</strong> (à l'ordre de "CSSB")
    <div className="paymentModeDescr">
        Informations demandées :
        <ul>
            <li>Montant du chèque vacance</li>
            <li>Montant du chèque complémentaire</li>
        </ul>
        La somme du montant des chèques doit être égale au prix total demandé. Adresse d'envoi :
        <ul>
            <li> {CHECK_POST_ADDRESS} </li>
        </ul>
    </div>
</div>
    )
}

function SponsorMode() {
    const url = "https://foreveryoungfoundation.fr/associations/secourisme-cssb/"


    return (
<div className="paymentMode">
    <strong>Paiement par parrainage</strong>
    <div className="paymentModeDescr">
        Information demandée :
        <ul>
            <li>Identité (nom & prénom) du parrain (ou de la marraine)</li>
        </ul>
        Lien donné :
        <a href={url} target="_blank" rel="noopener noreferrer">
                    {url}
                </a>
    </div>
</div>
    )
}

export default function PaymentMode({
    data,
}) {
    const discount = data.discounts?.length > 0 ? `(${data.discounts.map(d => d.label).join(", ")})` : ""
    const Mode = {
        "helloAsso": HelloAssoMode,
        "check": CheckMode,
        "vacationCheck": VacationCheckMode,
        "sponsor": SponsorMode,
    }[data.mode]

    return <Mode data={{...data, discount:discount}}/>
}