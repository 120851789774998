import axios from 'axios'
import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import FullPageLoading from '../../components/FullPageLoading'

import Header from "../../components/Header"
import TextInput from '../../components/Inputs/OldTextInput'

export default function ResetPassword({location}) {

	// TODO : add account address in the webpage

	const { token } = useParams()

	const [isLoading, setIsLoading] = useState(false)
	const [requestSuccess, setRequestSuccess] = useState(false)
	const [password, setPassword] = useState("")
	const [confirm, setConfirm] = useState(false)
    const [email, setEmail] = useState("")
    const [valid, setValid] = useState("")

	const [hint1, setHint1] = useState(
		"Veuillez entrer votre nouveau mot de passe"
	)
	const [hint2, setHint2] = useState("")

	function checkPassword(value) {
		setPassword(value)
		let ok = false
		if (value.toLowerCase() === value) {
			setHint1("Le mot de passe doit contenir au moins une majuscule")
		} else if (value.toUpperCase() === value) {
			setHint1("Le mot de passe doit contenir au moins une minuscule")
		} else if (!value.match(/[0-9]/)) {
			setHint1("Le mot de passe doit contenir au moins un chiffre")
		} else if (value.length < 8) {
			setHint1("Le mot de passe doit contenir au moins 8 caractères")
		} else {
			setHint1("")
			ok = true
		}
		setHint2(ok)
	}

	function handleSubmit(event) {
		event.preventDefault()

		if(password !== confirm)
			return toast.error("Les mots de passe ne correspondent pas")

		setIsLoading(true)

		axios.post("/api/auth/reset-password", { 
			token: token, 
			password: password 
		}).then(() => {
			setRequestSuccess(true)

		}).catch(err => {
			if(err.response?.data?.detail) {
				toast.error(err.response.data.detail.replace("Unauthorized", "Le lien de réinitialisation a expiré"))
			}
			else {
				console.log(err)
				toast.error("Une erreur est survenue")
			}
		}).finally(() => setIsLoading(false))
	}

    function toHHMMSS(sec) {
        var hours   = Math.floor(sec / 3600);
        var minutes = Math.floor((sec - (hours * 3600)) / 60);
        var seconds = sec - (hours * 3600) - (minutes * 60);
    
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}

        var out = minutes+'min'+seconds+"s";
        if (hours > 0) {out = hours+'h'+out;}
        return out;
    }

    useEffect(() => {

        setIsLoading(true)

		axios.get("/api/auth/reset-password/"+token, { 
			token: token, 
			password: "undef" 
		}).then(res => {
            setEmail(res.data.email)
            let message = ""
            if (res.data.valid > 0) {
                message = "Cette page est valide encore "+toHHMMSS(res.data.valid)
            } else {
                message = "Cette page n'est plus valide !"
            }
            setValid(message)
		}).catch(err => {
			if(err.response?.data?.detail) {
                console.log(err)
				toast.error("Token invalide")
			}
			else {
				console.log(err)
				toast.error("Une erreur est survenue")
			}
		}).finally(() => setIsLoading(false))

    }, [token])

	return (
<>
<Header />

<FullPageLoading show={isLoading} />

<main className="small-form">

	<div className="full-page-card">

		<div className="blue-title">Nouveau mot de passe</div>

		<div className="card-content">

			{!requestSuccess &&
				<>
				<div style={{"marginBottom": "20px"}}>
					Pour le compte associé à l'adresse email :
					<pre>{email}</pre>
                    {valid}
				</div>		

				<form className="login-form" onSubmit={handleSubmit}>

					{hint1 && 
					<div className="infos-box purple" id="hint">
						{hint1}
					</div>}

					<TextInput 
						type="password" 
						className="ml-15" 
						label="Mot de passe" 
						name="password" 
						value={password} 
						onChange={e => checkPassword(e.target.value)} 
					/>

					{hint2 && 
					<div className="infos-box green" id="hint">
						C'est parfait !<br></br> 
						Reste à le confirmer ci-dessous ...
					</div>}

					<TextInput 
						type="password" 
						className="ml-15" 
						label="Confirmez mot de passe" 
						name="confirmPassword" 
						value={confirm} 
						onChange={e => setConfirm(e.target.value)} 
					/>

					<button className="btn" type="submit">(Ré-)initialiser</button>

				</form>
				</>
			}

			{requestSuccess &&
				<>
				<div>
					Votre mot de passe a bien été modifié.
				</div>
				<Link to="/login">Aller à la page de connection</Link>
				</>
			}

		</div>

	</div>

</main>
</>
	)
}