import React, { useState, useEffect } from "react"

import { ReactComponent as CrossIcon } from "../../assets/icons/cross.svg"

function ListInput(
	{fID, label, value, disabled, handleChange, error}) {

	const [isFocused, setIsFocused] = useState(false)
	const [hasNewField, setHasNewField] = useState(false)

	useEffect(() => {
		setHasNewField(false);
	}, [disabled]);

	function handleFocus(idx){return () => setIsFocused(idx)}
	function handleBlur() { setIsFocused(false) }

	function changeItem(idx){
		return (e) => {
			if (disabled) {
				return
			}
			let newValue = [...value]
			newValue[idx] = e.target.value
			handleChange(newValue)
		}
	}

	function deleteItem(idx){
		return () => {
			if (disabled) {
				return
			}
			let newValue = [...value]
			newValue = value.slice(0, idx).concat(value.slice(idx+1))
			handleChange(newValue)
		}
	}

	function newField() {
		if(disabled || value[value.length-1] === "" ) return
		handleChange([...value, ""])
	}

	return (
		<div key={fID} className={
			"list-form-group" 
			+ (disabled ? " is-disabled" : "") 
			+ (error ? " has-error" : "")}>

			{value && value.length === 0 && (
				<p className={"single-field mt-5 label"}>{label} (aucun)</p>
			)}

            {value && value.map((item, idx) => {
                return (
					<div className={
						"single-field mt-5  has-content" 
						+ (isFocused === idx ? " is-focused" : "")
						} key={idx}
						>
						{label &&
							<label htmlFor={`input-${fID}-${idx}`} className="label">{label} {idx+1}</label>
						}
						<input
							key={idx}
							type="text"
							value={item}
							onChange={changeItem(idx)}
							id={`input-${fID}-${idx}`}
							name={fID+"-"+idx}
							onFocus={handleFocus(idx)}
							onBlur={handleBlur}
							disabled={disabled}
						/>
						<span className={"input-icon " + (disabled && "hidden")} 
							onClick={deleteItem(idx)}
						>
							<CrossIcon />
						</span>
						<div className="baseline"></div>

					</div>
                )
            }
            )}

            <div type="button" 
				className={"blue-link btn-link mt-15" + (disabled || hasNewField ? " hidden" : "")} 
				onClick={newField}
			>
                + <span className="hide-on-mobile">Ajouter</span>
            </div>

			<div className="errors">
				{error && 
				<div className="error-item"> {error} </div>
				}
			</div>

		</div>
	)
}

export default ListInput
