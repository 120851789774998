import React, { useEffect, useState } from "react"

import { RiCloseLine } from "react-icons/ri"
import { IoIosArrowDown } from "react-icons/io"

export default function SelectInput(props) {

	const findOption = value => {
		let selected = props.options.find(elem => (elem.value === value || (value._id && value._id === props.value._id)))

		if(!selected) {
			return props.defaultOption
		}
		else {
			return selected
		}
	}

	const [isFocused, setIsFocused] = useState(false)
	const [selectedOption, setSelectedOption] = useState((props.value !== undefined && props.value !== null) ? findOption(props.value) : (props.defaultOption ? props.defaultOption : undefined))
	const [hasContent, setHasContent] = useState(selectedOption ? true : false)

	const handleInputClick = e => {
		e.stopPropagation()
		setIsFocused(!isFocused)
	}

	const handleDocumentClick = e => {
		setIsFocused(false)
	}

	const handleOptionClick = e => {
		e.stopPropagation()

		setIsFocused(false)

		let { target } = e

		let clickedOptionIdx = target.attributes["data-idx"].nodeValue

		let newSelectedOption  = props.options[clickedOptionIdx]

		setSelectedOption(newSelectedOption)

		if(props.onChange) {
			props.onChange({ ...e,
				"target": {
					...e.target,
					"value": newSelectedOption.value,
					"name": props.name
				}
			})
		}

	}

	useEffect(() => {

		document.addEventListener("click", handleDocumentClick)

		return function() {
			document.removeEventListener("click", handleDocumentClick)
		}

	}, [])

	useEffect(() => {
		if(props.value !== undefined)
			setHasContent(true)
		else
			setHasContent(false)
	}, [props.value])

	useEffect(() => {

		if(props.value !== undefined) {

			let _selectedOption = props.options.find(elem => {
				if(elem.value === props.value ||
					(elem.value && elem.value._id && elem.value._id === props.value._id)
				)
					return true
				else
					return false
			})

			if(_selectedOption) {
				setSelectedOption(_selectedOption)
			}
		}

	}, [props.value, props.options])

	return (
		<div className={"form-group group-select" + (hasContent ? " has-content" : "") + (props.errors ? " has-error" : "") + (isFocused ? " is-focused" : "") + (props.className ? " " + props.className : "")}>

			{props.label &&
				<label onClick={handleInputClick} className="label">{props.label}</label>
			}

			<div className="select-input" name={props.name}>

				<div className="placeholder-values">
					<div className="placeholder-values-item">{props.defaultOption ? props.defaultOption.name : ""}</div>
					<div className="placeholder-values-item">{props.label}</div>
					{props.options.map((option, idx) => {
						return (
							<div key={idx} className="placeholder-values-item">{option.name}</div>
						)
					})}
				</div>

				<div className="selected-value" onClick={handleInputClick}>{selectedOption ? selectedOption.name : (props.defaultOption ? props.defaultOption.name : "")}</div>

				<div className="select-options">
					<div className="to-slide">
						{props.options.map((option, idx) => {
							if(selectedOption && option.value === selectedOption.value) return ""

							return (
								<div key={idx} onClick={handleOptionClick} className="option-item" data-idx={idx} data-value={option.value}>{option.name}</div>
							)
						})}
					</div>
				</div>

				<div className="errors">

					{props.errors && props.errors.map((error, idx) => {
						return (
							<div key={idx} className="error-item">
								{error}
							</div>
						)
					})}

				</div>

			</div>

			<div onClick={handleInputClick} className="input-icon">
				{!props.errors &&
					<IoIosArrowDown />
				}
				{props.errors &&
					<RiCloseLine />
				}
			</div>

			<div className="baseline"></div>

		</div>
	)
}