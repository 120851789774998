import React from "react"
import { CheckboxInput } from './Inputs'

export default function MultipleSelectInput (props) {

	const handleOptionClick = (e, option) => {

		let _e = { ...e }
		let newValue = props.value ? [...props.value] : []
		if(newValue.includes(option.value)) {
			// Remove option from value
			const index = newValue.indexOf(option.value)

			if(index > -1)
				newValue.splice(index, 1)

		} else {
			// Add option to value
			newValue = [...newValue, option.value]
		}
		e.target.name = props.name
		e.target.value = newValue
		e.target.category = props.category
		props.onChange(_e)
	}

	return (
		<div className={"multiple-select" + (props.show ? " is-visible" : "")}>

			{props.options.map((option, idx) => {

				return (
					<div key={idx} onClick={(e) => handleOptionClick(e, option)}>
						<CheckboxInput
							label={option.name}
							onChange={(e) => handleOptionClick(e, option)}
							name={option.name}
							checked={(props.value && props.value.includes(option.value)) ? true : false}
						/>
					</div>
				)
			})}

		</div>
	)
}