import axios from "axios";
import Cookies from "js-cookie"

class AuthService {

	static events = {};
	static refreshTokenTimeout = null
	static config = localStorage.getItem("config") ? JSON.parse(localStorage.getItem("config")) : null

    static addEventListener(name, handler) {
        if (this.events.hasOwnProperty(name))
			this.events[name].push(handler);
        else
			this.events[name] = [handler];
    }

    static removeEventListener(name, handler) {
        if (!this.events.hasOwnProperty(name))
            return;

        var index = this.events[name].indexOf(handler);
        if (index !== -1)
			this.events[name].splice(index, 1);
    }

    static fireEvent(name, args) {
        if (!this.events.hasOwnProperty(name))
            return;

        if (!args || !args.length)
            args = [];

        var evs = this.events[name], l = evs.length;
        for (var i = 0; i < l; i++) {
            evs[i].apply(null, args);
        }
    }

	static login(matricule, pass) {

		return new Promise((resolve, reject) => {

			axios.post("/api/auth/login", {
					matricule,
					password: pass
					}
				).then(response => {

					const { access_token, refresh_token, user } = response.data;
					if(access_token && refresh_token && user) {
						Cookies.set("access_token", access_token, {secure: true, sameSite: "None"});
						localStorage.setItem("refresh_token", refresh_token);

						this.fireEvent("signin", {})
						axios.defaults.headers.common['Authorization'] = "Bearer "+access_token
							 
						clearTimeout(this.refreshTokenTimeout)

						this.refreshTokenTimeout = setTimeout(() => {
							this.refreshToken()
						}, this.config.jwt.access_expires ? this.config.jwt.access_expires*750 : 1000*60*15) // refresh token before it expires or every 15 minutes

						return resolve(/*user*/)
					}

					reject(new Error("Une erreur est survenue"))
				}).catch(err => {

					if(err?.response?.data)
						return reject(new Error(err.response.data.detail))

					reject(new Error("Une erreur est survenue"))
				});

		})

	}

	static refreshToken() {

		return new Promise((resolve, reject) => {

			const refresh_token = localStorage.getItem("refresh_token")

			if(!refresh_token)
				return reject(new Error("No refresh token"))

			axios.get("/api/auth/refresh", {
				"headers": {
					"Refresh": "Bearer "+refresh_token
				}
			}).then(response => {

				// Retrieve new access token from API call
				const { access_token, user } = response.data;

				if(access_token && user) {

					Cookies.set("access_token", access_token, {secure: true, sameSite: "None"});
					axios.defaults.headers.common["Authorization"] = "Bearer "+access_token

					localStorage.setItem("user", JSON.stringify(user))

					clearTimeout(this.refreshTokenTimeout)

					this.refreshTokenTimeout = setTimeout(() => {
						this.refreshToken()
					}, this.config.jwt.access_expires ? this.config.jwt.access_expires*750 : 1000*60*15) 
					// refresh token before it expires or every 15 minutes

					return resolve()
				}
				reject(new Error("Problème avec la réponse"))

			}).catch(err => {

				if(err?.response?.data)
					return reject(new Error(err.response.data.detail))

				reject(new Error("Une erreur est survenue"))
			})

		})

	}

	static getCurrentUser() {
		return JSON.parse(localStorage.getItem('user'));
	}

	static hasProfile(profile) {
		return this.getCurrentUser().profiles.includes(profile)
	}

    static includeProfiles(profiles) {
        for (const profile of profiles) {
            if (this.getCurrentUser().profiles.includes(profile)) return true
        }
		return false
	}

    static getProfileDescription() {
        const profiles = this.getCurrentUser().profiles
        
        if (profiles.length === 0) return "parent";

        function cieDescr(profile) {
            const scope = profile.split("-")
            if (scope.length === 1) return "national"
            const idx = parseInt(scope[1])
            switch (idx) {
                case 0: return "du bureau national";
                case 1: return "de la 1ère Cie";
                default: return `de la ${idx}ème Cie`;
            }
        }

        if (profiles.length === 1) {
            const profile = profiles[0]
            if (profile.startsWith("CHIEF")) {
                return "chef "+cieDescr(profile)
            }
            if (profile.startsWith("STAFF")) {
                return "membre de triptyque "+cieDescr(profile)
            }
            if (profile.startsWith("ADMIN")) {
                return "secrétaire "+cieDescr(profile)
            }
            if (profile === "DEV") {
                return "dev-tout-puissant"
            }
        }
        if (profiles.length === 2) {
            if (profiles.includes("STAFF") & profiles.includes("ADMIN")) {
                return "directeur de camp"
            }
            if (profiles[0].startsWith("ADMIN") & profiles[1].startsWith("ADMIN")) {
                return "secrétaire"
            }
        }
        return "inconnu"
	}
}

export default AuthService