// Icons used for subscription status
import { ReactComponent as FireIcon } from './assets/icons/fire.svg'
import { ReactComponent as CheckIcon } from './assets/icons/small-check.svg'
import { ReactComponent as WarningIcon } from './assets/icons/warning.svg'
import { ReactComponent as CrossIcon } from './assets/icons/cross.svg'

// Action icons
import { ReactComponent as FilterIcon } from "./assets/icons/filter.svg"
import { ReactComponent as ExportIcon } from "./assets/icons/export.svg"
import { ReactComponent as EditIcon } from "./assets/icons/edit.svg"
import { ReactComponent as DeleteIcon } from "./assets/icons/delete.svg"

// Icons used for file types
import { ReactComponent as PDFIcon } from './assets/icons/pdf.svg'
import { ReactComponent as AVIIcon } from './assets/icons/avi.svg'
import { ReactComponent as CSVIcon } from './assets/icons/csv.svg'
import { ReactComponent as DOCIcon } from './assets/icons/doc.svg'
import { ReactComponent as PNGIcon } from './assets/icons/png.svg'
import { ReactComponent as JPGIcon } from './assets/icons/jpg.svg'
import { ReactComponent as MP3Icon } from './assets/icons/mp3.svg'
import { ReactComponent as MP4Icon } from './assets/icons/mp4.svg'
import { ReactComponent as SVGIcon } from './assets/icons/svg.svg'
import { ReactComponent as TXTIcon } from './assets/icons/txt.svg'
import { ReactComponent as UNDEFIcon } from './assets/icons/undef.svg'

// Arrow icons
import { ReactComponent as ReturnIcon } from "./assets/icons/return.svg"
import { ReactComponent as ArrowTopIcon } from "./assets/icons/arrow-top.svg"
import { ReactComponent as ArrowBotIcon } from "./assets/icons/arrow-bot.svg"

// From react-icons
import { 
    MdQuestionMark, MdFileUpload, MdEdit, MdCheck, MdDelete, MdRefresh,
    MdOutlineFileDownload
} from 'react-icons/md'
import { GoReport } from "react-icons/go";

// Function to access status icons
const getStatusIcon = label => {
	switch (label) {
		case 'Fire':
			return <FireIcon />;
		case 'Check':
			return <CheckIcon />;
		case 'Warning':
			return <WarningIcon />;
		case 'Cross':
			return <CrossIcon />;
		case 'Delete':
			return <DeleteIcon />;
		default:
			return <WarningIcon />;
	}
}

// Function to access file types icons
function getFileTypeIcon(ext) {
	switch (ext) {
		case 'pdf':
			return PDFIcon;
		case 'avi':
			return AVIIcon;
		case 'csv':
			return CSVIcon;
		case 'doc':
			return DOCIcon;
		case 'png':
			return PNGIcon;
		case 'jpg':
		case 'jpeg':
			return JPGIcon;
		case 'mp3':
			return MP3Icon;
		case 'mp4':
			return MP4Icon;
		case 'svg':
			return SVGIcon;
		case 'txt':
			return TXTIcon;
		default:
			return UNDEFIcon;
	}
}

// Return button icon and style depending on the action
function getActionButton(type) {
	switch (type) {
		case 'upload':
			return [MdFileUpload, ""];
		case 'edit':
			return [MdEdit, ""];
		case 'valid':
			return [MdCheck, "valid-btn"];
		case 'report':
			return [GoReport, "report-btn"];
		case 'delete':
			return [MdDelete, "danger-btn"];
        case 'help':
		default:
			return [MdQuestionMark, "help-btn"];
	}
}

// Generic component for an 
function ActionButton({
    type, 
    onClick, 
    tooltip
}) {
    const [Icon, style] = getActionButton(type)
    return (
<div className={`btn small-btn ${style} ${tooltip ? "tooltip" : ""}`} onClick={onClick}>
    <Icon/>{tooltip && <span className="tooltiptext">{tooltip}</span>}
</div>
    )

}

export {
    CheckIcon,
    WarningIcon,
	FilterIcon,
	ExportIcon,
	EditIcon,
	DeleteIcon,
	ReturnIcon,
	ArrowBotIcon,
	ArrowTopIcon,
    MdRefresh,
    MdOutlineFileDownload,
	getStatusIcon,
	getFileTypeIcon,
    ActionButton,
}
