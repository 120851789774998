import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import DashHeader from 'components/Dash/Header'
import DashSidebar from 'components/Dash/Sidebar'

import Table from "components/Table";
import MedicAct from "components/MedicAct";

import FullPageLoading from "components/FullPageLoading"
import { Collapsible } from "components/utils/dev"

export default function MedicActs({
    history
}) {

    const { eID } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [headers, setHeaders] = useState([])
    const [searchKeys, setSearchKeys] = useState([])
    const [filters, setFilters] = useState([])
    const [acts, setActs] = useState([])
    const [event, setEvent] = useState({})

    const loadActs = useCallback(() => {
        setIsLoading(true)
        axios.get(`/api/events/medic/${eID}/acts` 
        ).then(res => {
            setHeaders(res.data.headers)
            setActs(res.data.acts)
            setEvent(res.data.event)
            setSearchKeys(res.data.searchKeys)
            setFilters(res.data.filters)
        }).catch(err => {
            if (err?.response?.data.detail)
                toast.error(err.response.data.detail)
            else {
                console.log(err)
                toast.error("Erreur bizarre ...")
            }
        }).finally(() => setIsLoading(false))
    }, [eID]);

    useEffect(loadActs, [loadActs])

    const [actModal, setActModal] = useState(false)
    const [actData, setActData] = useState({})
    
    function handleClick(act) {
        setActData(act) 
        setActModal(true)
    }
    function handleClose() {
        setActData({})
        setActModal(false)
    }

	return (
		<div className="admin-page">

            <FullPageLoading show={isLoading}/>

			<DashSidebar current="medic" />
			<DashHeader paths={[
                { name: "Suivi Médical", url: "/dashboard/medic" },
                { name: event.title, url: `/dashboard/medic/${eID}` },
                { name: "Actes de Soin", url: `/dashboard/medic/${eID}/acts` }
            ]} />

            <MedicAct 
                show={actModal}
                eID={eID}
                data={actData}
                onClose={handleClose}
                reload={loadActs}
            />

			<div className="admin-body">

                <div className="admin-section">
                    Liste des actes de soins effectués pendant l'évènement
                </div>

                {headers && acts &&
                <Table
                    uniqueID="my-events"
                    headers={headers}
                    data={acts}
                    searchKeys={searchKeys}
                    filters={filters}
                    dataName="acte"
                    onItemClicked={act => handleClick(act)}
                />}

                {/* For development and debug ... */}
                <Collapsible header="headers" data={headers} />
                <Collapsible header="acts" data={acts} />

			</div>

			

		</div>
	)
}