import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import './styles/admin.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter as Router } from "react-router-dom";
import ErrorCatcher from './ErrorsCatcher';
import axios from "axios"
import { ConfigurationProvider } from './components/ConfigContext';

window.cookieConsent = null

if (process.env.NODE_ENV === "development") {
	window.baseURL = "http://localhost:2004";	// Actually server URL ...
	window.clientURL = "http://localhost:2005";	// For the client URL ...
} else {
	window.baseURL = "https://central.cssb.fr";
	window.clientURL = "https://central.cssb.fr";
}

axios.defaults.baseURL = window.baseURL

if(localStorage.getItem("cookie-consent"))
	window.cookieConsent = localStorage.getItem("cookie-consent")

ReactDOM.render(
	<ConfigurationProvider>
		<React.StrictMode>
			<Router>
				<ErrorCatcher />
			</Router>
		</React.StrictMode>
	</ConfigurationProvider>,
	document.getElementById('root')
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
