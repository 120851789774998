import React, { useState, useEffect } from "react";
import axios from "axios";

import DashHeader from "components/Dash/Header";
import DashSidebar from "components/Dash/Sidebar";
import { MyDataInfos } from "components/Dash/Infos"

import Table from "components/Table";
import { Collapsible } from "components/utils/dev";
import FullPageLoading from "components/FullPageLoading";

export default function MyData ({
    history,  // Note : history is implicitly passed as component argument
}) {    
    const [headers, setHeaders] = useState([])
    const [members, setMembers] = useState([])

    const [isLoading, setIsLoading] = useState(false)
	
    useEffect(() => {
        setIsLoading(true)
        axios.get("/api/accounts/my-data"
        ).then(res => {
            setHeaders(res.data.headers);
            setMembers(res.data.members);
        }).catch(err => {
            console.log("Error ", err)
        }).finally(() => setIsLoading(false))

    }, []);

    return (
<div className="admin-page">

    <FullPageLoading show={isLoading}/>

    <DashSidebar current="my-data" />
    <DashHeader paths={[{ name: "Mes Données", url: "/dashboard/my-data" }]}/>

    <div className="admin-body">
        
        <MyDataInfos/>
        
        {headers && members &&
        <Table
            uniqueID="my-data"
            headers={headers}
            data={members}
            onItemClicked={member => history.push(`/dashboard/my-data/${member.matricule}`)}
        />}

        {/* For development and debug ... */}
        <Collapsible header="headers" data={headers} />
        <Collapsible header="members" data={members} />

    </div>

</div>
    );
};
