import React, { useState, useEffect } from "react";
import { useParams } from 'react-router'
import axios from "axios";
import DashHeader from "../../components/Dash/Header";
import DashSidebar from "../../components/Dash/Sidebar";
import MemberShortDisplay from "../../components/Admin/MemberShortDisplay";
import CreateAccountPopup from "../../components/Admin/CreateAccountPopup";

function Account() {
    const [account, setAccount] = useState(null);
    const [showCreateMember, setShowCreateMember] = useState(false)
    const { accountId } = useParams()

    useEffect(() => {
        const fetchAccount = async () => {
            const response = await axios.get(`/api/accounts/${accountId}`);
            setAccount(response.data);
        };
        fetchAccount();
    }, [accountId]);

    const handleCreateMember = () => {
        setShowCreateMember(true);
    };

    return (
        <div className="admin-page">

        <DashSidebar current="accounts" />

        <DashHeader paths={[
            {
                name: "Gestion des comptes",
                url: "/dashboard/account"
            },
            {
                name: account?.email || "Compte",
                url: "/dashboard/account"
            }
        ]} />

        <div className="admin-body">

            <h1>Account</h1>
            <div className="btn" onClick={handleCreateMember}>+ Nouveau membre sur ce compte</div>

            <h2>Profiles</h2>
            <div>Auto-filtering select input</div> 

           <h2>Ajouter un matricule déjà existant</h2> 
            <div>Auto-filtering select input, et popup pour dire les comptes auxquels il est associé?</div> 

            <p>
                {JSON.stringify(account)}
            </p>

            Membres associés
            {account?.matricules && Object.keys(account.matricules).map((matricule, idx) => (
                <div key={idx}>
                    <MemberShortDisplay account={account.matricules[matricule]} matricule={matricule} />
                </div>
            ))}



        </div>

        {showCreateMember && (
                <CreateAccountPopup
                    onReject={() => setShowCreateMember(false)}
                    onConfirm={() => setShowCreateMember(false)}
                    prefill={{
                        "email": account.email,
                        "lastName": account.matricules[Object.keys(account.matricules)[0]].lastName ?? "",
                        "phones": account.phones ?? []
                    }}
                />
            )}

        </div>
    );
};

export default Account;
