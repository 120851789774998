import React, { useCallback, useEffect, useState } from "react"
import axios from "axios"
import { useParams } from 'react-router'
import { toast } from "react-toastify"

import AutoInput from "components/Inputs/AutoInput"

import FullPageLoading from 'components/FullPageLoading'
import FileDisplay from "components/Admin/FileDisplay"

import DashHeader from "components/Dash/Header"
import DashSidebar from "components/Dash/Sidebar"
import MemberHeader from "components/Admin/MemberHeader"
import { MemberInfo } from "components/Dash/Infos"

import { Collapsible } from "components/utils/dev"

export default function Member({
    path,   // Note : path implicitly transmitted through the <DashRoute> component
}) {
    const from = path.split("/")[2]
    const fromTitle = {
        "listing": "Listing",
        "my-data": "Mes Données",
        "medic": "Fiche Médicale",
        "presence": "Présences"
    }[from]
    const medic = (from === "medic") ? "?medic=1" : ""

    const [fields, setFields] = useState({})
    const [member, setMember] = useState({})
    const [problems, setProblems] = useState({})
    const [labels, setLabels] = useState({})
    
    const [isLoading, setIsLoading] = useState(false)
    const [cieDisplay, setCieDisplay] = useState({
        "options": [],
        "scope": 1
    })
    const [memberTypeDisplay, setMemberTypeDisplay] = useState({
        "options": [],
        "scope": 1
    })

    const { matricule } = useParams()

    const reloadMember = useCallback(() => {
        setIsLoading(true)
        axios.get(`/api/members/${matricule}${medic}`).then(res => {
            setFields(res.data["fields"])
            setMember(res.data["member"])
            setProblems(res.data["problems"])
            setLabels(res.data["labels"])
            setCieDisplay({
                "options": res.data["fields"]["Header"].company.options,
                "scope": res.data["fields"]["Header"].company.scope,
            })
            setMemberTypeDisplay({
                "options": res.data["fields"]["Header"].memberType.options,
                "scope": res.data["fields"]["Header"].memberType.scope,
            })
        }).catch(err => {
            if (err.response)
                toast.error(err.response.data.detail)
            else {
                toast.error("Erreur bizarre")
                console.log(err)
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }, [matricule, medic])

    useEffect(() => {
        reloadMember();
    }, [reloadMember])

    let statusNonOK = false;
    for (const [fID, prb] of Object.entries(problems)) {
        if (prb && labels[fID]) { statusNonOK = true }
    }

    return (
<>

<FullPageLoading show={isLoading} />

<div className="admin-page">

    <DashSidebar current={from} />

    <DashHeader paths={[
        {
            name: fromTitle,
            url: `/dashboard/${from}`
        },
        {
            name: member?.firstName + " " + member?.lastName, // Love it !
            url: `/dashboard/${from}/${matricule}`
        }

    ]} withSwitch={false} />
    {/* TODO : no reload of the member when using the switch, has to be solved ... */}

    <div className="admin-body">
        <div className="member">

            <MemberHeader
                from={from}
                member={member}
                cieDisplay={cieDisplay}
                memberTypeDisplay={memberTypeDisplay}
                onUpdate={reloadMember}
            />

            <MemberInfo from={from} problems={problems} labels={labels}/>

            {Object.keys(fields
                ).filter(category => (category !== "Fichiers" && category !== "Header")
                ).map((category) => (
                <section key={category} className="member-basic">

                    <div className="section-header flex flex-space-between">
                        <div className="section-title" >{category}</div>
                    </div>

                    <div className="section-content">
                        {Object.entries(fields[category]).map(([fID, field]) => {
                            return (
                                <AutoInput
                                    key={fID}
                                    fID={fID}
                                    field={{...field, problem:problems[fID]}}
                                    setLoading={setIsLoading}
                                    apiURI={`/api/members/${matricule}`}
                                    statusNonOK={statusNonOK}
                                    updateProblems={(newProblems) => setProblems(newProblems)}
                                />
                            )
                        })}
                    </div>
                </section>
            ))}

            <section className="member-basic member-files">

                <div className="section-header flex flex-space-between">
                    <div className="section-title">Fichiers</div>
                </div>

                <div className="section-content">
                    <div>
                        {fields["Fichiers"] && Object.entries(fields["Fichiers"]
                            ).map(([fID, field]) => {
                            if (field || field.scope > 1)
                            return (
                            <FileDisplay
                                key={fID}
                                matricule={member.matricule}
                                fID={fID}
                                field={field}
                                problems={problems}
                                statusNonOK={statusNonOK}
                                updateProblems={(newProblems) => setProblems(newProblems)}
                            />
                            )
                            else return null
                        })
                        }
                    </div>
                </div>

            </section>

        </div>

        {/* For development and debug ... */}
        <Collapsible header="member" data={member} />
        <Collapsible header="fields" data={fields} />
        <Collapsible header="problems" data={problems} />

    </div>

</div>
</>
    )
}