import React from "react"
import { Link } from 'react-router-dom'

import '../styles/header.css'

const Header = props => {
	return (
		<header className="header">

			<Link to="/" className="logo">
				CSSB - Central
			</Link>

			<nav>
				{props.nav !== false &&
					<ul>
						<li><Link to="/">Accueil</Link></li>
						<li><a href="https://infos.cssb.fr/camp" target="_blank" rel="noreferrer">Informations Utiles</a></li>
					</ul>
				}
			</nav>

			<Link to="/contact" className="btn outline-btn contact-btn">
				Contact
			</Link>

		</header>
	)
}
export default Header