import React from "react"

import '../styles/modal.css'

const Modal = props => {
	const handleOverlayClick = e => {
		if(props.onClose)
			props.onClose()
	}
	if(!props.show)
		return null
	return (
		<>
			<div className="overlay" onClick={handleOverlayClick}></div>

			<div className={"modal" + (props.className ? " " + props.className : "") + (props.fixed ? " fixed" : "")}>
				{props.children}
			</div>
		</>
	)
}

export default Modal