import React, { useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"

import FullPageLoading from "components/FullPageLoading"
import TextInput from 'components/Inputs/OldTextInput'
import { realDiscount } from "utils/events"

function HelloAssoChoice({
    data,
    event,
    onRegistration,
}) {
    const [loading, setLoading] = useState()

    function register() {
        setLoading(true)
        axios.post(`/api/events/${event.eID}/register`, {
            "mode": "helloAsso",
            "data": data,
            "amount": event.amount,
            "matricules": data.matricules,
        }).then(res => {
			toast.success(res.data.status)
            onRegistration()
		}).catch(error => {
			if (error?.response?.data.detail) {
				toast.error(JSON.stringify(error.response.data.detail))
			} else {
				toast.error("Erreur bizarre ...")
				console.log("erreur", error)
			}
		}).finally(() => {
            setLoading(false)
        })
    }

    return (
<div className="paymentMode">
    <strong>Par carte bancaire via HelloAsso</strong>
    <div className="paymentModeDescr">
        Le paiement s'effectue via un lien vers la plateforme HelloAsso (indiqué une fois l'inscription enregistrée). 
        Juste avant de payer, vous devrez indiquer le matricule du ou des membres pour qui le paiement est fait,
        afin que l'inscription soit validée automatiquement une fois le paiement reçu.
    </div>
    {event.amount ?
    <div style={{marginTop: "6px"}}>
        Montant total à régler : {event.amount}€ {event.userDiscount}
    </div> :
    <div style={{marginTop: "6px"}}>
        Montant variable, indiqué sur la page de payement HelloAsso ...
    </div>
    }
    <div className="register-btn-group">
        <button className="btn" onClick={register}> Choisir ce mode de paiement </button>
    </div>
    <FullPageLoading show={loading} />
</div>
    )
}

function CheckChoice({
    data,
    event,
    onRegistration,
}) {
    const [loading, setLoading] = useState()
    const [amounts, setAmounts] = useState([0, 0, 0])
    const [bank, setBank] = useState("")

    function changeAmount(idx, newValue) {
        var newAmounts = [...amounts]
        newAmounts[idx] = newValue
        setAmounts(newAmounts)
    }

    function register() {
        setLoading(true)
        axios.post(`/api/events/${event.eID}/register`, {
            "mode": "check",
            "data": {
                "bank": bank,
                "amounts": amounts,
            },
            "amount": event.amount,
            "matricules": data.matricules,
        }).then(res => {
			toast.success(res.data.status)
            onRegistration()
		}).catch(error => {
			if (error?.response?.data.detail) {
				toast.error(JSON.stringify(error.response.data.detail))
			} else {
				toast.error("Erreur bizarre ...")
				console.log("erreur", error)
			}
		}).finally(() => {
            setLoading(false)
        })
    }
    return (
<div className="paymentMode">
    <strong>Par chèque(s)</strong>
    <div className="paymentModeDescr">
        Le paiement est effectué par chèque à l'ordre de "CSSB", éventuellement en plusieurs fois avec plusieurs chèques, et envoyé par voie postale.
    </div>
    <div style={{marginTop: "6px"}}>
        Montant total à régler : {event.amount}€ {event.userDiscount}
    </div>
    <div style={{marginTop: "6px"}}>
        Veuillez indiquer ci-dessous les montants de chaque chèque 
        (laisser vide les autres champs si vous voulez payer en une ou deux fois) :

        <form className="contact-form" style={{"margin": "10px 0px"}}>
            <div className="card-content text-left">
                <div className="flex flex-space-between">
                    <div className="flex flex-1">
                        <TextInput
                            className="flex-1 mr-30"
                            label="Nom de la Banque"
                            name="bank"
                            value={bank}
                            onChange={(event) => setBank(event.target.value)}
                        />
                    </div>
                    {[0, 1, 2].map(idx => {return (
                        <div key={idx} className="flex flex-1">
                            <TextInput
                                className="flex-1 mr-30"
                                label={`Montant chèque n°${idx+1}`}
                                name={`amount-${idx}`}
                                value={amounts[idx]}
                                onChange={(event) => changeAmount(idx, event.target.value)}
                            />
                        </div>)}
                    )}
                </div>
            </div>
        </form>

        <strong>Important :</strong> préciser au dos des chèques le ou les matricules des membres concernés par le paiement, 
        ainsi que les dates d'encaissement voulues.
    </div>
    <div className="register-btn-group">
        <button className="btn" onClick={register}> Choisir ce mode de paiement </button>
    </div>
    <FullPageLoading show={loading} />
</div>
    )
}

function VacationCheckChoice({
    data,
    event,
    onRegistration,
}) {
    const [loading, setLoading] = useState()
    const [mainAmount, setMainAmount] = useState(0)
    const [complement, setComplement] = useState(0)

    function register() {
        setLoading(true)
        axios.post(`/api/events/${event.eID}/register`, {
            "mode": "vacationCheck",
            "data": {
                "mainAmount": mainAmount,
                "complement": complement,
            },
            "amount": event.amount,
            "matricules": data.matricules,
        }).then(res => {
			toast.success(res.data.status)
            onRegistration()
		}).catch(error => {
			if (error?.response?.data.detail) {
				toast.error(JSON.stringify(error.response.data.detail))
			} else {
				toast.error("Erreur bizarre ...")
				console.log("erreur", error)
			}
		}).finally(() => {
            setLoading(false)
        })
    }

    return (
<div className="paymentMode">
    <strong>Par chèque vacance</strong>
    <div className="paymentModeDescr">
        Le paiement est effectué par chèque vacance à l'ordre de "CSSB", complété éventuellement par un chèque normal, et envoyé par la poste.
    </div>
    <div style={{marginTop: "6px"}}>
        Montant total à régler : {event.amount}€ {event.userDiscount}
    </div>
    <div style={{marginTop: "6px"}}>
    Veuillez indiquer ci-dessous le montant du chèque vacance, ainsi que celui du complément éventuel :
    </div>
    <form className="contact-form" style={{"margin": "10px 0px"}}>
        <div className="card-content text-left">
            <div className="flex flex-space-between">
                <div className="flex flex-1">
                    <TextInput
                        className="flex-1 mr-30"
                        label="Montant du chèque vacance"
                        name="mainAmount"
                        value={mainAmount}
                        onChange={(event) => setMainAmount(event.target.value)}
                    />
                </div>
                <div className="flex flex-1">
                    <TextInput
                        className="flex-1 mr-30"
                        label="Complément (chèque normal)"
                        name="complement"
                        value={complement}
                        onChange={(event) => setComplement(event.target.value)}
                    />
                </div>
            </div>
        </div>
    </form>
    <strong>Important :</strong> préciser au dos des chèques le ou les matricules des membres concernés par le paiement.
    <div className="register-btn-group">
        <button className="btn" onClick={register}> Choisir ce mode de paiement </button>
    </div>
    <FullPageLoading show={loading} />
</div>
    )
}

function SponsorChoice({
    data,
    event,
    onRegistration,
}) {
    const [loading, setLoading] = useState()
    const [sponsor, setSponsor] = useState("")

    function register() {
        setLoading(true)
        axios.post(`/api/events/${event.eID}/register`, {
            "mode": "sponsor",
            "data": {
                "sponsor": sponsor,
            },
            "amount": event.amount,
            "matricules": data.matricules,
        }).then(res => {
			toast.success(res.data.status)
            onRegistration()
		}).catch(error => {
			if (error?.response?.data.detail) {
				toast.error(JSON.stringify(error.response.data.detail))
			} else {
				toast.error("Erreur bizarre ...")
				console.log("erreur", error)
			}
		}).finally(() => {
            setLoading(false)
        })
    }

    return (
<div className="paymentMode">
    <strong>Par parrainage via Forever Young Foundation</strong>
    <div className="paymentModeDescr">
        Le paiement est effectué par un parrain (membre de la famille ou autre), qui paye <strong>pour le ou les membres à inscrire à l'évènement</strong>,
        via le site de don ForeverYoung Foundation. Ce don peut être défiscalisé, et sera fléché directement vers le budget de fonctionnement de l'évènement.
    </div>
    <div style={{marginTop: "6px"}}>
        Montant total à régler : {event.amount}€ {event.userDiscount}
    </div>
    <div style={{marginTop: "6px"}}>
    Veuillez indiquer ci-dessous l'identité complète (nom & prénom) du parrain :
    </div>
    <form className="contact-form" style={{"margin": "10px 0px"}}>
        <div className="card-content text-left">
            <TextInput
                className="flex-1 mr-30"
                label="Identité du parrain"
                name="sponsor"
                value={sponsor}
                onChange={(event) => setSponsor(event.target.value)}
            />
        </div>
    </form>

    <div className="register-btn-group">
        <button className="btn" onClick={register}> Choisir ce mode de paiement </button>
    </div>
    <FullPageLoading show={loading} />
</div>
    )
}

export default function PaymentChoice({
    mode, 
    data,
    event,
    onRegistration,
}) {
    const [discount, amount] = realDiscount(event.userDiscounts, event.amount, mode);
    const Choice = {
        "helloAsso": HelloAssoChoice,
        "check": CheckChoice,
        "vacationCheck": VacationCheckChoice,
        "sponsor": SponsorChoice,
    }[mode]
    return <Choice 
                data={data}
                event={{...event, amount:amount, userDiscount:discount}}
                onRegistration={onRegistration}
            />
}