import React, { useCallback, useEffect, useState } from "react"
import axios from "axios"
import { useParams } from 'react-router'
import { toast } from "react-toastify"
import { Link } from "react-router-dom"


import FullPageLoading from 'components/FullPageLoading'

import DashHeader from "components/Dash/Header"
import DashSidebar from "components/Dash/Sidebar"
import PaymentInfo from "pages/Events/comp/PaymentInfos"

import { Collapsible } from "components/utils/dev"

export default function Register({
    path,   // Note : path implicitly transmitted through the <DashRoute> component
}) {
    const { eID, matricule } = useParams()
    const from = path.split("/")[2]

    const [isLoading, setIsLoading] = useState(false)
    const [event, setEvent] = useState({})
    const [members, setMembers] = useState([])

    const reloadEvent = useCallback(() => {
        setIsLoading(true)
        axios.get(`/api/events/${eID}/${matricule}`).then(res => {
            setEvent(res.data.event)
            setMembers(res.data.members)
        }).catch(err => {
            if (err.response)
                toast.error(err.response.data.error)
            else {
                toast.error("Une erreur est survenue")
                console.log(err)
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }, [eID, matricule])

    useEffect(() => {
        reloadEvent();
    }, [reloadEvent])

    return (
<>

<FullPageLoading show={isLoading} />

<div className="admin-page">

    <DashSidebar current={from} />

    <DashHeader paths={[
        {
            name: "Évènements",
            url: `/dashboard/${from}`
        },
        {
            name: event?.title,
            url: `/dashboard/${from}/${eID}`
        },
        {
            name: matricule,
        }

    ]} />

    <div className="admin-body">

        {members.length !== 0 ?
        <div className="admin-section">
            Statut d'inscription pour :
            <ul>
                {members.map((member, idx) => 
                    <li key={idx}> {member.firstName} {member.lastName} ({member.matricule}, fiche membre : {member.status}) 
                    -- <strong> {event.status} </strong>
                    </li>
                )}
            </ul>
        </div> 
        :
        <div className="admin-section">
            Évènement non accessible pour ce matricule
        </div> 
        }

        {!event?.authorized && event.registered &&
        <div className="admin-section">
            <strong>Inscription non autorisée</strong> : cette personne a été inscrite, mais entre-temps ça fiche a été changée et n'est plus à jour ... 
        </div>}

        

        {event.registered && !event.paid &&
        <>Informations affichées sur son espace personnel :
        <div className="admin-section">
            <PaymentInfo 
                data={{...event.registered, amount:event.amount, discounts:event.userDiscounts, eID:event.eID}}
            /> 
        </div>
        </>}

        {event.registered && event.paid &&
        <>
        <PaymentInfo 
            data={{...event.registered, amount:event.amount, discounts:event.userDiscounts, eID:event.eID}}
            infoOnly={true}
        /> 
        <div className="paymentMode">
            Paiement reçu le {event.paid}
            {!event.validated &&
                <div style={{textAlign: "center"}}>
                    <strong >
                    Le paiement reçu est inférieur au montant attendu ({event.amount}€)
                    <br/>
                    Veuillez contacter rapidement le Service Informatique CSSB : service.informatique@cssb.fr ...
                </strong>
                </div>
            }
        </div>
        </>}
        
        <Link to={`/dashboard/listing/${matricule}`} className="btn bold" style={{width:"fit-content"}}> Voir la fiche membre </Link>
        
        {/* For development and debug ... */}
        <Collapsible header="event" data={event} />
        <Collapsible header="members" data={members} />
    </div>

</div>
</>
    )
}