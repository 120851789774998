import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const ConfigurationContext = createContext();

const ConfigurationProvider = ({ children }) => {
    const [config, setConfig] = useState(null);

    useEffect(() => {
        async function fetchConfig() {
            try {
                const response = await axios.get('/api/public/config').then(res => res.data)
                setConfig(response)
                localStorage.setItem('scopes', JSON.stringify(response.scopes))
                localStorage.setItem('membersData', JSON.stringify(response.membersData));

                delete response.scopes;
                delete response.membersData;
                localStorage.setItem('config', JSON.stringify(response));
            } catch (error) {
                console.error('Error loading configuration:', error);
            }
        }

        fetchConfig();
    }, []);

    return (
        <ConfigurationContext.Provider value={config}>
            {children}
        </ConfigurationContext.Provider>
    );
};

const useConfiguration = () => {
    return useContext(ConfigurationContext);
};

export { ConfigurationProvider, useConfiguration };