import Modal from "components/Modal"

function HelpPopup({label, content, show, onClose}) {
    return (
<Modal fixed={true} className="confirm-modal" show={show} onClose={onClose}>

    <div className="form-title title mb-30">{label}</div>

    <div className="form-group has-content" style={{width: "800px"}}>

    <div dangerouslySetInnerHTML={{ __html: content }} style={{textAlign: "justify"}}/>

    </div>

    <div className="flex center gap-20 flex-horizontal" style={{marginTop: "30px"}}>
        <div className="pointer blue-link" onClick={e => onClose(e)}>Bien compris, fermer ce message</div>
    </div>

</Modal>
    )
}

export {
    HelpPopup
}