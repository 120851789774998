import React, { useState, useCallback } from "react"
import { useParams } from 'react-router'
import axios from 'axios'
import { toast } from "react-toastify"

import { MdOutlineFileDownload } from "icons"
import MedicAct from "components/MedicAct"

export default function MedicHeader(){

    const {eID, matricule} = useParams() 
    const [actModal, setActModal] = useState(false)
    
    const getMedicSheet = useCallback(() => {
        if (matricule) {
            axios.get(`/api/members/${matricule}/medicSheet`, { 
                responseType: "blob" 
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `fiche_sanitaire_${matricule}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }).catch(err => {
                toast.error("Erreur lors du téléchargement")
                console.log(err)
            })
        }
    }, [matricule])

    return (
<div className="member-name">

    <div className="btn bold" onClick={() => setActModal(true)}>
        + Soin
    </div>

    <MedicAct 
        show={actModal}
        eID={eID} 
        matricule={matricule} 
        onClose={() => setActModal(false)}
    />
    <div className="btn bold" onClick={() => getMedicSheet()}>
        <MdOutlineFileDownload/> FSL
    </div>
</div>
)
}